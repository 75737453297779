
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import SelectFilterItemsModal from '@app/modules/filter/blocks/SelectFilterItemsModal.vue';

export default defineComponent({
    name: 'Filters',
    components: {SelectFilterItemsModal},
    props: {
        filterItems: {
            type: Array,
            required: true,
        },
        activeFilterItem: {
            type: Object,
            default: null,
        },
    },
    emits: ['showEditFilterItemsModal', 'changeFilterItem'],

    data() {
        return {};
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),

        visibleFilters() {
            const items = this.filterItems.slice(0, 5);
            if (this.activeFilterItem && !items.some(item => item.id === this.activeFilterItem?.id)) {
                items.pop();
                items.push(this.activeFilterItem);
            }
            return items;
        },
    },

    methods: {
        onShowSelectFilterItemsModal() {
            this.$refs.selectFilterItemsModal.show();
        },

        onShowEditFilterItemsModal() {
            this.$emit('showEditFilterItemsModal');
        },

        onSelectFilterItem(item) {
            this.changeFilterItem(item);
        },

        changeFilterItem(filterItem) {
            this.$emit('changeFilterItem', filterItem)
        }
    },
});

