export default {
    // https://rec.dev.ntrnx.com/api/dev-oauth/auth/login
    // baseUrl: "https://rec.dev.ntrnx.com",
    // baseUrl: "https://rec2.ntrnx.com",

    recognitionMode: +(localStorage.getItem('recognitionMode') || 1),

    baseUrl: window.location.origin,
    apiVersionStr: 'api/' + process.env.VUE_APP_BASE_URL + '/',
    // apiVersionStr: "api/develop/",
    // apiVersionStr: "api/dev-oauth",

    // videoPath: window.location.origin + "/api/develop/",
    // photoPath: window.location.origin + "/api/develop/",

    videoPath: 'https://rec.ntrnx.com/api/master/video_storage/vinteo/',
    photoPath: 'https://rec.ntrnx.com/api/master/photos/',

    /* Scale video timeline */
    videoTimelineScale: {
        // TODO: default value (timeline without scaling)
        // scaleX: 1,
        scaleX: 0.1,
        minScaleX: 0.1,
        maxScaleX: 5,
        stepScaleX: 0.1,
    },

    /* Video note editor */
    notes: {
        prefix: 'notes',
    },

    identityServerOauth: false,
};
