
import {defineComponent} from 'vue';

import Modal from '@app/components/ui-kit/molecules/Modal.vue';
import {IFilterSetDto} from '@app/system/api/service-proxies/service-proxies';

export default defineComponent({
    name: 'SelectFilterItemsModal',
    components: {
        Modal,
    },
    props: {
        filterItems: {
            type: Array,
            required: true,
        },
        activeFilterItem: {
            type: Object,
            default: null,
        },
    },
    emits: ['selectItem'],
    data() {
        return {
            id: 'SelectFilterItemsModal',
            items: this.filterItems as IFilterSetDto[],
            modalShow: false,
            serverErrorMessage: null,
        };
    },
    computed: {},
    created() {
    },
    methods: {
        show() {
            this.modalShow = true;
        },

        hide() {
            this.modalShow = false;
        },

        async resetModal() {
        },

        async closeModal() {
            this.hide();
        },

        onSelectFilterItem(item: IFilterSetDto): void {
            this.$emit('selectItem', item);
            this.closeModal();
        },
    },
});
