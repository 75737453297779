
//import Sidebar from '@app/components/Sidebar';

import {defineComponent} from 'vue';

export default defineComponent({
    name: 'PanelVideoTemplate',
    components: {
        //    Sidebar,
    },
    data() {
        return {
            sidebarOpened: false,
        }
    },
    methods: {
        async sidebarToggleCallback(opened) {
            this.sidebarOpened = opened;
        },
    },
});
