import {api} from '@app/system/api/api-services';

function initialState() {
    return {
        isMlEnabled: false
    };
}

const getters = {
    isMlEnabled(state) {
        return state.isMlEnabled
    }
};

const actions = {
    async getIsMlEnabled({commit}) {
        try {
            const resp = await api.appServiceProxy.common('1.0').toPromise();
            // console.log('isMlEnabled ===> resp', resp);
            commit('setIsMlEnabled', resp);
        } catch (error) {
            console.error('Error in isMlEnabled:', error);
        }
    },
};

const mutations = {
    setIsMlEnabled(state, value) {
        const [hours, minutes, seconds] = value.checkProcessingStateInterval.split(':').map(Number);
        const milliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;

        state.isMlEnabled = {
            checkProcessingStateInterval: milliseconds,
            value: value.isMlEnabled
        }
    }
};

/* eslint-enable no-param-reassign */
export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
