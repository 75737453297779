export function generateRandomString(length: number): string {
    let str = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#';
    const digits = '0123456789';
    const symbols = '!#';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';

    str += digits.charAt(Math.floor(Math.random() * digits.length));
    str += symbols.charAt(Math.floor(Math.random() * symbols.length));
    str += uppercase.charAt(Math.floor(Math.random() * symbols.length));
    str += lowercase.charAt(Math.floor(Math.random() * symbols.length));

    for (let i = 0; i < length - 4; i++) {
        str += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return str;
}
