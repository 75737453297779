
import moment from 'moment';

import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";


import DropdownMenu from "@app/components/DropdownMenu.vue";
import MessagePlate from "@app/components/MessagePlate.vue";
import SearchRow from "@app/components/SearchRow.vue";
import ButtonCreate from "@app/components/ui-kit/molecules/ButtonCreate.vue";
import Loader from "@app/components/ui-kit/molecules/Loader.vue";
import ModalDelete from "@app/components/ui-kit/molecules/ModalDelete.vue";
import CopyVideoLinkBtn from "@app/modules/video/blocks/CopyVideoLinkBtn.vue";
import CreateAccessModal from "@app/modules/video/blocks/CreateAccessModal.vue";
import { IAccessTicketDto } from "@app/system/api/service-proxies/service-proxies";

const avatarPlaceholderImg = require('@app/assets/icons/avatar_placeholder.png');
export default defineComponent({
    name: "Accesses",
    components: {
        CopyVideoLinkBtn,
        MessagePlate,
        ButtonCreate,
        SearchRow,
        Loader,
        ModalDelete,
        DropdownMenu,
        CreateAccessModal,
    },
    props: {
        tabIsSelected: {
            type: Function,
            required: true
        },
        getVideoPersons: {
            type: Function,
            required: false,
            default() {
                return () => [];
            }
        },
        protocolHeight: {
            type: Number,
            default: 0
        },
    },
    emits: ['edit', 'remove'],
    data() {
        return {
            avatarPlaceholderImg,
            permissions: {
                update: 'Recorder.Participant.Update',
                delete: 'Recorder.Participant.Delete'
            },
            selectedAccess: null,
            titleModal: null,
            accessName: null,
            accessType: null,

            messageSuccessCopyVideoUrl: false,

            paginationInfo: {
                perPage: 20,
                currentPage: 1,
            },

            isAccessSearch: false
        }
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isGrantedPolicyForSome: 'isGrantedPolicyForSome',
        }),
        ...mapGetters('video', {
            error: 'error',
            isLoading: 'isLoading',
            accessUrls: 'accessUrls',
            accessPersons: 'accessPersons'
        }),
        ...mapState('video', [
            'video',
            'searchStr',
            'accessPersonsTotalCount'
        ]),
        totalCountAccesses() {
            return this.accessPersonsTotalCount
        },
        isGrantedForCreate() {
            return this.isGrantedPolicyForSome([
                'Recorder.Video.ManagePermissions'
            ]);
        },
        isGrantedForUpdate() {
            return this.isGrantedPolicyForSome([
                'Recorder.Video.Update'
            ]);
        }
    },

    watch: {
        'paginationInfo.currentPage'() {
            this.getAccessesPersons(this.paginationInfo)
        },
    },

    async created() {
        this.setSearchStr('')
        if (!this.accessUrls.length) {
            await this.getAccessesUrls(this.paginationInfo)
        }

        if (this.isGrantedForCreate && !this.accessPersons.length) {
            await this.getAccessesPersons(this.paginationInfo)
        }
    },

    methods: {
        ...mapActions('video', {
            getAccessesPersons: 'getAccessesPersons',
            getAccessesUrls: 'getAccessesUrls',
            deleteAccessPerson: 'deleteAccessPerson',
            deleteAccessUrls: 'deleteAccessUrls'
        }),
        ...mapMutations('video', {
            setSearchStr: 'setSearchStr',
            resetSearchStr: 'resetSearchStr'
        }),

        getFormattedDate(url: IAccessTicketDto): string {
            return moment(url.expiresAfter).subtract(1, 'day').format('YYYY-MM-DD');
        },

        addAccesses() {
            this.$refs.createAccessModal.show();
        },

        async removeAccesses(access, type) {
            if (type === 'url') {
                this.titleModal = this.$t('modal_titles.delete_access_url')
                this.accessType = 'accessUrl'
            } else {
                this.titleModal = this.$t('modal_titles.delete_access_person')
            }
            this.selectedAccess = access
            this.$refs.modalDelete.show();
        },

        async accessDelete() {
            if(this.accessType !== 'accessUrl') {
                this.deleteAccessPerson(this.selectedAccess?.userId || this.selectedAccess?.id)
                    .then(() => {
                        this.getAccessesPersons(this.paginationInfo)
                    });
            } else {
                this.deleteAccessUrls(this.selectedAccess.id)
                    .then(() => {
                        this.getAccessesUrls(this.paginationInfo)
                    });
            }
        },

        async accessCreate(createAccessUrl) {
            if (createAccessUrl) {
                await this.getAccessesUrls(this.paginationInfo)
            } else {
                await this.getAccessesPersons(this.paginationInfo)
            }
        },

        getTextSearchStr(text) {
            this.isAccessSearch = !!text;
            this.accessType = null
            this.setSearchStr(text)
            this.paginationInfo.currentPage = 1
            const self = this;
            clearInterval(this.ts);
            if (this.isGrantedForCreate) {
                this.ts = setTimeout(() => {
                    self.getAccessesPersons(this.paginationInfo)
                    self.getAccessesUrls(this.paginationInfo)
                }, 250);
            }
        },

        showMessageSuccessCopyVideoUrl(index) {
            this.$refs.messageCopyLink.forEach((msg, i) => {
                msg.classList.remove('active');
                if (index === i) {
                    msg.classList.add('active');
                    const url = this.$refs.plateListAccessUrls[i];
                    if (url) {
                        msg.style.top = `${url.clientHeight}px`;
                    }
                    setTimeout(() => {
                        msg.classList.remove('active');
                    }, 2000);
                }
            });
        }
    }
})
