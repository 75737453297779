import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c3040d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "button",
  class: "wrap"
}
const _hoisted_2 = ["id", "title"]
const _hoisted_3 = { class: "plate-user" }
const _hoisted_4 = {
  key: 0,
  ref: "dropdownWrap",
  class: "dropdown-menu dropdown-menu-wide dropdown-menu-action show position-absolute",
  "aria-labelledby": "more_users_2",
  "x-placement": "bottom-start"
}
const _hoisted_5 = { class: "dropdown-menu-inner staff-in-video" }
const _hoisted_6 = { class: "dropdown-menu-title" }
const _hoisted_7 = { class: "dropdown-menu-inner-data scroll-content custom-scroll pr-3" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "col-auto pr-1" }
const _hoisted_11 = {
  key: 0,
  class: "col-auto ml-auto"
}
const _hoisted_12 = { class: "sticker-red" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.config.recognitionMode && _ctx.video)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          id: 'tooltip-target-users-' + _ctx.video.id,
          ref: "btnUsers",
          type: "button",
          class: "btn-users position-relative",
          "data-toggle": "tooltip",
          title: _ctx.$t('tooltips.persons_on_video'),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)), ["stop"]))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slicePersons(_ctx.video), (participant) => {
            return (_openBlock(), _createElementBlock("span", {
              key: participant.id,
              class: "plate-user",
              style: _normalizeStyle({ backgroundImage: 'url(' + (participant.primaryPhoto?.url || _ctx.avatarPlaceholderImg) + ')' })
            }, null, 4))
          }), 128)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.video.participants?.length || 0), 1),
          (_ctx.dropdownVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('titles.staff_on_video')), 1),
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.video.participants, (participant) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: participant.id,
                        class: _normalizeClass(["row no-gutters flex-nowrap align-items-center clickable", _ctx.isGrantedPolicy('Recorder.Participant', true) ? false : 'no-hover']),
                        onClick: ($event: any) => (_ctx.isGrantedPolicy('Recorder.Participant', true) ? _ctx.editPerson(participant) : false)
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", {
                            class: "user-avatar-midi mr-2",
                            style: _normalizeStyle({ backgroundImage: 'url(' + (participant.primaryPhoto?.url || _ctx.avatarPlaceholderImg) + ')' })
                          }, null, 4)
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", {
                            class: _normalizeClass([{ 'color-gray-8': !participant.fullName }, "text-small font-medium full-name-max-width"])
                          }, _toDisplayString(_ctx.getPersonDisplayLabel(participant)), 3)
                        ]),
                        (!participant.state)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('tooltips.not_recognized')), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ], 10, _hoisted_8))
                    }), 128))
                  ])
                ])
              ], 512))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.dropdownVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "overlay",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
        }))
      : _createCommentVNode("", true)
  ], 512))
}