
import {defineComponent} from 'vue';

import {setShowUserActivityExpiredModalHandler} from "@app/system/api/xhr-factory";
import Modal from "@app/components/ui-kit/molecules/Modal.vue";

export default defineComponent({
    name: 'UserActivityExpiredModal',
    components: {
        Modal,
    },
    data: () => ({
        modalShow: false,
    }),
    mounted() {
        setShowUserActivityExpiredModalHandler(() => {
            this.modalShow = true;
        });
    },
    methods: {

        handleClose() {
            const $oidc = window['$oidc'];

            this.modalShow = false;

            $oidc.signOut();
        },

    },
});
