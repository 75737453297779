import io from 'socket.io-client';

import {config} from '@app/config';

// WebSocket connection varible
export let socket = null;

/* Events for sending data and statuses */

// Join to editing a note
export function wsJoinNoteEditing(jwt, noteId) {
    if (!noteId) {
        return;
    }

    // Create WebSocket connection

    socket = io(`${config.baseUrl}/${config.notes.prefix}`, {
        path: '/api/' + process.env.VUE_APP_BASE_URL + '/socket.io',
        transports: ['polling', 'websocket'],
        transportOptions: {
            polling: {
                extraHeaders: {
                    'Authorization': 'Bearer ' + jwt,
                },
            },
        },
    });

    const payload = {
        'noteId': noteId,
    };

    if (jwt) {
        payload['authToken'] = jwt;
    }

    // socket.emit('join', payload, (respData) => {
    //   console.log('[wsJoinToEditingNote]', respData);
    // });
    socket.emit('join', payload);
}

// Send note changes
export function wsApplyNoteChanges(jwt, noteId, changes, startPosition, replaceLength) {
    if (!noteId) {
        return;
    }

    const payload = {
        'authToken': jwt,
        'noteId': noteId,
        // Modified text only
        'changes': changes,
        // Start index of changed text in note text
        'startPosition': startPosition,
        // Length of changed text in note text
        'replaceLength': replaceLength,
    };

    // socket.emit('apply', payload, (respData) => {
    //   console.log('[wsApplyNoteChanges]', respData);
    // });
    socket.emit('apply', payload);
}

// Sending a message that the user started typing a note
export function wsNoteTypingStarted(jwt, noteId) {
    if (!noteId) {
        return;
    }

    const payload = {
        'authToken': jwt,
        'noteId': noteId,
    };

    // socket.emit('apply_typing_started', payload, (respData) => {
    //   console.log('[wsNoteTypingStarted]', respData);
    // });
    socket.emit('apply_typing_started', payload);
}

// Sending a message that the user ended typing a note
export function wsNoteTypingEnded(jwt, noteId) {
    if (!noteId) {
        return;
    }

    const payload = {
        'authToken': jwt,
        'noteId': noteId,
    };

    // socket.emit('apply_typing_ended', payload, (respData) => {
    //   console.log('[wsNoteTypingEnded]', respData);
    // });
    socket.emit('apply_typing_ended', payload);
}

// Close WebSocket
export function wsCloseNoteEditing() {
    socket?.close();
}

/* /Events for sending data and statuses */

/* Callbacks returning data from the server */

// User joined
export function wsCbJoinedNoteEditing(cbFunc) {
    socket.on('joined', (respData) => {
        cbFunc(respData);
    });
}

// Note changed
export function wsCbNoteChanged(cbFunc) {
    socket.on('changed', (respData) => {
        cbFunc(respData);
    });
}

// User started typing
export function wsCbStartedType(cbFunc) {
    socket.on('start_type', (respData) => {
        cbFunc(respData);
    });
}

// User ended typing
export function wsCbEndedType(cbFunc) {
    socket.on('end_type', (respData) => {
        cbFunc(respData);
    });
}

/* /Callbacks returning data from the server */
