
import {defineComponent} from 'vue'

export default defineComponent({
    name: 'BIcon',
    props: {
        icon: {
            type: String,
            required: true
        },
    },
});
