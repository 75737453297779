export const mocks = {
    usersSearchResp: {
        'offset': 0,
        'limit': 10,
        'count': 32,
        'results': [{
            'id': 93,
            'login': 'r.asanov@ntrnx.com',
            'vinteo_id': '106',
            'videos_count': 3,
            'conferences': null,
        },
            {'id': 88, 'login': 'p.pashchenko@ntrnx.com', 'vinteo_id': '256', 'videos_count': 25, 'conferences': null},
            {'id': 87, 'login': '233', 'vinteo_id': '233', 'videos_count': 39, 'conferences': null},
            {'id': 34, 'login': 'svisaew@ntrnx.com', 'vinteo_id': '163', 'videos_count': 36, 'conferences': null},
            {'id': 33, 'login': 'Oleg', 'vinteo_id': '', 'videos_count': 0, 'conferences': null},
            {'id': 32, 'login': 'user', 'vinteo_id': '', 'videos_count': 0, 'conferences': null},
            {'id': 31, 'login': 'golovanov.i@ntrnx.com', 'vinteo_id': '227', 'videos_count': 90, 'conferences': null},
            {'id': 30, 'login': '219', 'vinteo_id': '219', 'videos_count': 10, 'conferences': null},
            {'id': 29, 'login': 'a.purtov@ntrnx.com', 'vinteo_id': '150', 'videos_count': 58, 'conferences': null},
            {'id': 28, 'login': '212', 'vinteo_id': '212', 'videos_count': 0, 'conferences': null},
        ],
    },
    apiMasterJwt: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6dHJ1ZSwiaWF0IjoxNjU4ODI0MDM3LCJqdGkiOiJkYWEzNjE1ZS1iYTc0LTRlMjQtYmVhMy1hNDEyNWUxMGFkODUiLCJ0eXBlIjoiYWNjZXNzIiwic3ViIjp7ImV4cCI6IlRodSwgMjggSnVsIDIwMjIgMjA6Mjc6MTcgR01UIiwiaWF0IjoiVHVlLCAyNiBKdWwgMjAyMiAwODoyNzoxNyBHTVQiLCJ1c2VyX2lkIjoxLCJsb2dpbiI6ImFkbWluIiwiaXNfYWRtaW4iOnRydWUsInJvbGUiOiJhZG1pbiJ9LCJuYmYiOjE2NTg4MjQwMzcsImV4cCI6MTY1ODgyNzYzN30.9HDxdgEFhI2NLumgB0gcm71BZ5-hVHdkgW6Brg_u7FM',
};
