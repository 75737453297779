import {i18n} from '@app/i18n';
import {api} from '@app/system/api/api-services';
import {
    SpecificationParameters,
} from '@app/system/api/service-proxies/service-proxies';

function initialState() {
    return {
        isLoading: false,
        isServerError: false,
        serverErrorMessage: null,
        securityLogsList: null,
        perPage: 100,
        currentPage: 1,
        logsCount: 0,

        searchStr: '',
        parameterName: null,
    };
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },

    isServerError(state) {
        return state.isServerError;
    },

    serverErrorMessage(state) {
        return state.serverErrorMessage;
    },

    perPage(state) {
        return state.perPage;
    },

    currentPage(state) {
        return state.currentPage;
    },

    securityLogsList(state) {
        return state.securityLogsList;
    },
    logsCount(state) {
        return state.logsCount;
    },
};

const actions = {

    async getSecurityLogs({commit, state}, sortingDirection) {
        commit('setLoading');
        const pageSize = state.perPage;
        const pageNumber = state.currentPage;
        const searchStr = state.searchStr;
        const parameterName = state.parameterName;

        const expandedSearch  = new SpecificationParameters({
            specId: '4295ADC0-0740-404F-9991-CAC67A9A6D5A',
            parameters: {value: `%${searchStr}%`},
        });

        const sortByParameterName = new SpecificationParameters({
            specId: '',
            parameters: {isDescending: sortingDirection},
        });

        if (parameterName === 'action') sortByParameterName.specId = 'B35A6784-D165-482F-9445-C52B23D3FE73';
        if (parameterName === 'date') sortByParameterName.specId = 'C6606EB6-D1B2-430B-8EC2-5579E6A89F31';
        if (parameterName === 'user') sortByParameterName.specId = '840096F4-9AD1-4182-AA3D-6652EA57D941';
        if (parameterName === 'ip') sortByParameterName.specId = 'AB2A33AB-3DD4-4DE4-9D69-EF14CDDC6CF1';
        if (parameterName === 'browserInfo') sortByParameterName.specId = '4E8DC4C0-D19F-4725-895E-7B35BD9493B7';

        const specifications = [];

        if (searchStr.length) {
            specifications.push(expandedSearch);
        }
        if (parameterName) {
            specifications.push(sortByParameterName);
        }
        return await api.securityLogServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications).toPromise()
            .then(resp => {
                    // console.log('resp getSecurityLogs ===>', resp);
                    commit('setLoaded');
                    commit('setLogs', resp);
                    commit('setLogsCount', resp.totalCount);
                },
            )
            .catch((err) => {
                commit('setLoaded');
                commit('setServerError', err);
            });

    },

};

/* eslint-disable no-param-reassign */
const mutations = {
    setLoading(state) {
        state.isLoading = true;
        state.isServerError = false;
        state.serverErrorMessage = null;
    },

    setLoaded(state) {
        state.isLoading = false;
    },

    setServerError(state, err) {
        state.isServerError = true;
        if (err.response && err.response.data && err.response.data.error) {
            state.serverErrorMessage = err.response.data.error;
        } else {
            state.serverErrorMessage = i18n.global.t('unknown_error');
        }
    },

    setPaginationInfo(state, paginationInfo) {
        state.perPage = paginationInfo.perPage;
        state.currentPage = paginationInfo.currentPage;
    },

    setSearchStr(state, searchStr) {
        state.searchStr = searchStr;
    },

    setLogs(state, logs) {
        state.securityLogsList = logs;
    },

    resetSearchStr(state) {
        state.searchStr = '';
    },
    setLogsCount(state, count) {
        state.logsCount = count;
    },
    setParameterName(state, type) {
        state.parameterName = type
    }
};

/* eslint-enable no-param-reassign */
export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
