import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae43f198"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "b_loader_wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    ref: "modal",
    modelValue: $props.modalShow,
    "onUpdate:modelValue": [
      _cache[3] || (_cache[3] = ($event: any) => (($props.modalShow) = $event)),
      $options.onClickCancel
    ],
    width: $props.width,
    persistent: true,
    "no-click-animation": "",
    onClose: $options.onClickCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString($props.title), 1),
          _createElementVNode("button", {
            class: "btn-close",
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClickCancel && $options.onClickCancel(...args)))
          })
        ]),
        _createElementVNode("div", {
          class: "modal-body",
          style: _normalizeStyle({overflow: $props.isOverflowVisible ? 'visible' : 'auto'})
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4),
        ($props.withFooter)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["modal-footer", $props.isAdditionalButton ? 'edit-video' : false])
            }, [
              _renderSlot(_ctx.$slots, "additionalButton", {}, undefined, true),
              _createElementVNode("div", {
                class: "block-btns d-flex",
                style: _normalizeStyle($props.isAdditionalButton ? {width: 'auto'} : {})
              }, [
                ($props.withCancel)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-secondary btn-md",
                      type: "button",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onClickCancel && $options.onClickCancel(...args)))
                    }, _toDisplayString($props.cancelTitle), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  class: _normalizeClass(["btn btn-md", $props.isDelete ? 'del' : ' btn-primary']),
                  type: "button",
                  disabled: $props.okDisabled,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onClickOk && $options.onClickOk(...args)))
                }, _toDisplayString($props.okTitle), 11, _hoisted_4)
              ], 4)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      ($props.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Loader)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["modelValue", "width", "onClose", "onUpdate:modelValue"]))
}