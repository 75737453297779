
import {defineComponent} from 'vue';
import {mapActions, mapGetters} from 'vuex';

import {appSettings, config} from '@app/config';

export default defineComponent({
    name: 'Header',
    components: {},
    props: [
        'titlePage'
    ],
    data: function () {
        return {
            recognitionMode: config.recognitionMode,
            firstChar: null,
            baseUrl: appSettings.authBaseUrl,
        }
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            userEmail: 'userEmail',
        }),
    },

    methods: {
        ...mapActions('auth', {
            signOut: 'signOut',
        }),
        async logout() {
            await this.signOut();
            await this.$router.push({name: 'logout'});
        },
        mobileMenuOpen() {
            document.body.className += ' mobile-menu--open';
        },
    },
    mounted() {
        this.firstChar = this.userEmail().charAt().toUpperCase()
    }
});
