

import {defineComponent} from 'vue';

import Modal from "@app/components/ui-kit/molecules/Modal.vue";
import {deleteModalBackdrop} from '@app/utils/helpers';

export default defineComponent({
    name: 'ModalDelete',
    components: {
        Modal,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        questionText: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
        selectedVideos: {
            type: Number,
            default: null
        },
    },
    emits: ['delete'],

    data: function () {
        return {
            modalShow: false,
        }
    },
    watch: {
        '$route'(to) {
            deleteModalBackdrop(to)
        },
    },
    methods: {
        async submitForm() {
            this.$emit('delete');
            await this.closeModal();
        },

        show() {
            this.modalShow = true;
        },

        hide() {
            this.modalShow = false;
        },

        async closeModal() {
            this.hide();
        },
    },
});
