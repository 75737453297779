export default {
    /* Titles */

    auth: {
        signin_title: 'Sign in',
        signup_title: 'Sign up',
        login_label: 'Login',
        login_placeholder: 'Your login',
        password_label: 'Password',
        forgot_password: 'forgot password',
        signin_btn: 'Login',
        footer_all_rights_tip: 'All rights reserved',
        footer_personal_data_tip: 'Personal data processing agreement',
        footer_privacy_policy: 'Privacy Policy',
        oauth_fail_msg: 'Invalid response received from Identity Server. Try to log in again',
        logouted_msg: 'You are logged out.',
        login_again: 'Login again',
    },

    page_titles: {
        main_page: 'Main page',
        video: 'Videos',
        staff: 'Staff members',
        unrecognized: 'Unrecognized persons',
        broadcasts: 'Broadcasts',
        users: 'Users',
        roles: 'Roles',
        error: 'Error',
        journal: 'Journal',
        system: 'System',
        vinteo_server_settings: 'Vinteo server settings',
        files_auto_delete_settings: 'Настройки политик автоудаления файлов',
        updates: 'Updates',
        vsp_settings: 'VSP settings',
        network_settings: 'Network settings',
        copy_video_inner_link: 'Copy video inner link',
        copy_video_public_link: 'Copy video public link',
        video_access_denied: 'You don\'t have access to this video',
        participant_not_recognized: 'Participant not recognized',
        action_logs: 'Action logs',
        branding_settings: 'Appearance settings',
        notifications_preferences: 'Notification settings',
        licenses: 'Licenses',
    },

    modal_titles: {
        new_user: 'New user',
        delete_role: 'Delete role',
        disabling_deletion_protection: 'Disabling deletion protection',
        delete_user: 'Delete user',
        delete_access_url: 'Deleting link access',
        delete_access_person: 'Deleting user access',
        delete_person: 'Delete employee',
        new_person: 'New person',
        new_license: 'New license',
        united_persons: 'United person',
        united_persons_subtitle: 'Select the main participant with whom the duplicates will be combined',
        video_description: 'Editing videos',
        save_filter: 'Save filter',
        edit_filter: 'Edit filters',
        select_filter: 'Select filter',
        delete_video: 'Delete video',
        delete_license: 'Delete license',
        add_person: 'New person',
        add_role: 'Add role',
        add_license: 'Add license',
        access_editing: 'Access editing',
        role_editing: 'Role editing',
        employee_editing: 'Employee editing',
        add_user: 'Add user',
        user_editing: 'User editing',
        access_video: 'Providing access to video',
        ended_session_message: 'Your session was automatically terminated due to a long period of inactivity',

        message_for_ldap_users: '* This is an external account. Password and full name change for external accounts is performed in the service where the account was created',

        // Editing user roles
        role_name: 'Role name',
        general_rights: 'General rights',
        editing_user_roles: 'Editing user roles',
        editing_system_settings: 'Editing system settings',
        viewing_system_logs: 'Viewing the system logs',
        video_rights: 'Video Rights',
        view: 'View',
        editing: 'Editing',
        deleting: 'Deleting',
        providing_public_link: 'Providing a public link',
        condition: 'Condition',
        apply_filter: 'Apply filter',
        add_another_condition: 'Add another condition',
        filter_message: 'There are no videos that meet the conditions',
        saved_filters: 'Saved filters',
        identity_role_assign: 'Linking to system roles',
        identity_roles: 'System roles',

        // Errors validations
        passwordLength: 'At least 8 characters',
        lowercaseLetters: 'Lowercase letters (a to z)',
        uppercaseLetters: 'Upper case letters (A to Z)',
        numbers: 'Numbers (0 to 9)',
        specialCharacters: 'Special characters (for example: !, $, #, %)',
        invalidEmail: 'A valid email is required',
        minLength: 'At least 2 characters',
        ip_address: 'Enter a valid IP address',
        incorrect_data: 'Incorrect data',
        illegalCharacters: 'Illegal characters',
        firstAndLastNameRequired: 'Enter first and last name',
        minLength_maxLength: 'At least 2 and no more than 128 characters',
        password_policy: 'In accordance with the requirements of the password policy',

        // Subtitles
        participant_number: 'Participant number',
    },

    system_title: {
        address: 'Address',
        address_placeholder: 'Enter address',
        ip_address: 'IP address',
        ip_address_placeholder: 'Enter IP address',
        login: 'Login',
        password: 'Password',
        hide_show: 'Show/Hide',
        apply_changes: 'Apply changes',
        upload_update_file: 'Upload file with updates',
        timezone: 'Vinteo server time zone',
        ignore_ssl_errors: 'Ignore SSL certificate validation errors',
        is_request_logging_enabled: 'Logging API requests to the Vinteo server',
        is_enable_vinteo_integration: 'Enable Vinteo integration',
        minimum_length_of_processed_video: 'Minimum length of processed video, seconds',
        maximum_length_of_processed_video: 'Maximum length of processed video, seconds',
        interval_for_checking_new_videos_in_vinteo: 'Interval for checking new videos in Vinteo',
        interval_of_polling_ml_service_about_processing_status: 'Interval of polling ML-service about processing status',
        is_process_vinteo_audio_protocols: 'Process Vinteo audio protocols',
        processed_videos_preserve_interval: 'Delete processed videos via',
        processed_videos_preserve_interval_day: 'Day of the week (1-7)',
        is_auto_delete_processed_videos: 'Automatic deletion of processed videos',
    },

    table_titles: {
        login: 'Login',
        fullname_short: 'Full Name',
        first_name: 'First Name',
        last_name: 'Last Name',
        date: 'Date',
        duration: 'Duration',
        name: 'Name',
        staff: 'Staff',
        access: 'Access',
        public_link: 'Public link',
        follow: 'Follow',
        mark_to_delete: 'Mark to delete',
        mark_to_save: 'Prohibition of deletion',
        delete: 'Delete',
    },

    journal_table_titles: {
        date: 'Date',
        caller: 'Caler',
        action: 'Action',
        video_id: 'ID Video',
    },

    video_list: {
        protocol_records: 'Records in protocol',
    },

    video_access_types: {
        all_users: 'All registered users',
        all_allowed_users: 'Only invited people',
        public: 'Anyone with a link',
    },

    titles: {
        avatar: 'Avatar',
        fullname: 'Full name',
        first_name: 'First Name',
        middle_name: 'Patronymic',
        last_name: 'Last Name',
        login: 'Login',
        email: 'Email',
        password: 'Password',
        information: 'Information',
        total: 'Total',
        staff: 'Staff',
        phrases: 'Phrases',
        conferences: 'Conferences',
        name: 'Name',
        personal_data: 'Personal data',
        person: 'Person',
        note: 'Note',
        user: 'User',
        vinteo_id: 'Сonference moderator subscriber ID (for Vinteo)',
        protocol: 'Protocol',
        chat: 'Chat',
        participants: 'Participants',
        files: 'Files',
        ssl_certificate: 'SSL certificate',
        ssl_key: 'SSL key',
        update_file: 'File with updates',
        staff_on_video: 'Staff on video',
        users_with_access: 'Users with access',
        timeline: 'Timeline',
        accesses: 'Accesses',
        unique_key_license: 'Unique key',
        license_key: 'License key'
    },

    buttons_common: {
        select_file: 'Select file  .... ',
        generate: 'Generate',
        save: 'Save',
        copy: 'Copy',
        upload: 'Upload',
        exit: 'Exit',
        cancel: 'Cancel',
    },

    /* Buttons */
    buttons: {
        create_user: 'Create user',
        create_person: 'Create person',
        save: 'Save',
        cancel: 'Cancel',
        unite: 'Unite',
        share: 'Share',
        delete: 'Delete',
        deleted: 'Deleted',
        disconnect: 'Disconnect',
        disable_delete: 'Disable delete',
        details: 'Details',
        logout: 'Log out',
        enable_access_to_all: 'Enable access to all',
        copy_link_for_video: 'Copy link',
        profile: 'Profile',
        goTo: 'Go to',
        provide: 'Provide',
        download_logs: 'Download logs',
        go_to_main_page: 'Go to main page',
        notifications_preferences: 'Notifications preferences',
        copy: 'Copy',
        download_key_from_file: 'Load key from file',
    },

    // Placeholders
    placeholders_common: {
        choose_file: 'Choose a file or drop it here...',
        drop_placeholder: 'Drag and drop file here ...',
        no_file_selected: 'No file selected',
        search: 'Search',
        staff_search: 'Staff search',
        search_video: 'Video search',
        user_search: 'User search',
        name: 'Name (optional)',
        validity_period: 'Validity period',
        search_log: 'Log search',
        access_name_or_email: 'Name or email'
    },

    /* Left navs */
    navs: {
        video: 'Video',
        staff: {
            general: 'Staff members',
            recognized: 'Recognized',
            unrecognized: 'Unrecognized',
        },
        broadcasts: 'Broadcasts',
        users: 'Users',
        roles: 'Roles',
        journal: 'Journal',
        system: 'System',
        audit_logs: 'Action logs',
        by_link: 'By link',
        by_email: 'By email',
        branding_settings: 'Appearance',
        licenses: 'Лицензии',
    },

    /* Other */
    view_placeholder_text: 'Content here',
    not_found: 'Not Found',
    unknown_error: 'Unknown error',
    empty_list: 'Empty list',
    delete_role_text: 'Delete role',
    delete_license_text: 'Delete license',
    disable_deletion_protections: 'Disable deletion protection from',
    delete_user_text: 'Delete user',
    delete_person_text: 'Delete employee',
    delete_video_text: 'Delete video',
    delete_videos_text: 'Delete',
    delete_user_text_info: 'To delete a user, go to the interface',
    delete_access: 'Are you sure you want to remove the user\'s access to this video',


    alerts_common: {
        messageTitle: 'Message',
        linkForTheVideoHaveBeenCopied: 'A link for the video have been copied',
        something_wrong: 'Something wrong!',
        settings_saved: 'Settings have been saved',
        invalid_parameter_format: 'Invalid parameter format!',
        attention_server_will_restart_after_update: 'Attention! The server will restart after the update.',
        file_must_have_a_extension: 'The file must have a extension {extension}',
        failed_to_download_update: 'Failed to download update!',
        update_was_downloaded_successfully: 'The update was downloaded successfully. The server will restart.',
        attention_overwriting_nginx_config_file: 'Attention! Applying the changes will overwrite the Nginx configuration file, after which the server will be rebooted.',
    },

    /* Dropdown filter */
    dropdown_filter: {
        // Container
        title: 'Filter',
        column: 'Column',
        add_filter: 'Add filter',
        filters: 'filters',
        apply: 'Apply filter',
        save: 'Save filter',
        // Selects
        equal: 'Equal',
        not_equal: 'Not equal',
        before: 'Before',
        after: 'After',
        contain: 'Contain',
        not_contain: 'Not contain',
        access_granted: 'Access granted',
        access_not_granted: 'Access not granted',
        present: 'Present',
        not_present: 'Not present',
        moderates: 'Moderates',
        not_moderates: 'Not moderating',
        there_is: 'There is',
        there_not_is: 'There not is',
        period: 'Period',
        period_from: 'Period from',
        period_by: 'by',
        yes: 'Yes',
        no: 'No',
        interval: 'Interval',

        link_for_all: 'Access by link for all visitors',
        link_for_all_users: 'Access by link for all registered users',
        link_for_allowed_users: 'Access by link for all allowed users',

        and: 'And',
        or: 'Or',
        // Field names
        date: 'Date',
        time: 'Time',
        name: 'Name',
        title_contains: 'The title contains',
        title_not_contains: 'The title does not contain',
        conference_name: "Conference title",
        video_name: "Video title",
        conference: 'Conference',
        staff: 'Staff',
        access: 'Access',
        public_link: 'Public link',
        caller: 'Caller',
        action: 'Action',
        video: 'Video Id',
        moderator: 'Moderator',
        deletion_protection: 'Deletion protection',
        confidentially: 'Confidentially',
        roomNumber: 'Room number',
        room_number_equal: 'The room number is',
        room_number_not_equal: 'The room number is not equal to',
        duration: 'Duration',
        date_does_not_equal: 'The date does not equal',
        date_for: 'For',
        duration_from: 'Range from',
        duration_to: 'to',
        duration_less_than_or_equal_to: 'Duration less than or equal to',
        removal_protection: 'With removal protection',
        not_removal_protection: 'No deletion protection',
    },

    /* Video instance page */
    video_instance: {
        protocol: 'Protocol',
        notes: 'Notes',
        duration: 'Duration',
        employees: 'Employees',
        public_link: 'Public link',
        typing_notes: 'no body enters text | 1 user enters text | {n} users enter text',
        protocol_search_placeholder: 'Search in the protocol',
        unrecognized: 'Unrecognized',
    },
    protocol: {
        protocol_editing: 'To edit the protocol',
        cancel: 'Cancel',
        save_changes: 'Save changes',
        delete_message: 'Delete an entry',
        video_processing: 'Video is being processed. Completed'
    },

    accesses: {
        add: 'Add',
    },

    // enums
    enums: {
        // Actions
        actions: {
            access_granted: 'Access granted',
            access_denied: 'Access denied',
            video_made_public: 'Made public for anyone with link',
            video_made_available_for_all_users: 'Made public for all registered users',
            video_made_available_for_allowed_users: 'Made private',
            watch_video: 'Watch video',
            delete_video: 'Delete video',
            video_appeared: 'Video appeared',
        },
    },

    tooltips: {
        logout: 'Logout',
        persons_on_video: 'Persons on video',
        users_with_access: 'Users with access',
        edit: 'Edit',
        delete: 'Delete',
        available_videos: 'Available videos',
        add_person: 'Add user',
        videos_with_person: 'Videos with person',
        update: 'Update',
        not_recognized: 'Not recognized',
        copy: 'Copy',

        event_attending_the_meeting: 'Attending the meeting',
        event_talk: 'Talk',
        event_present_on_the_video: 'Present on the video',
        event_positive_emotion: 'Positive emotion',
        event_fear_emotion: 'Fear emotion',
        event_negative_emotion: 'Negative emotion',
        event_surprise_emotion: 'Surprise emotion',
        event_disgust_emotion: 'Disgust emotion',
        cancel_save: 'Exit without saving',
        save_protocol: 'Save the protocol',
        video_trim: 'Trim video',
        access_blocked_delete: 'To restrict a user from accessing this video, you must take away the role that allows access to this video',
        recognize_repeatedly: 'Recognize repeatedly',
        confidential_info: 'You can\'t make a public viewing link to a confidential video',
        video_trim_disabled: 'Editing videos shorter than 1 minute 30 seconds is not possible'
    },

    // Search
    search: {
        person: 'No persons | Person | Persons',
        text: 'No texts | Text | Texts',
        note: 'No notes | Note | Notes',
        user: 'No users | User | Users',
        video: 'No videos | Video | Videos',
        role: 'Sorry, the role was not found. Change the search condition.',
        userSearch: 'Sorry, no user was found. Change the search condition.',
        participant: 'Sorry, no member was found. Change the search condition.',
        access: 'Access not found. Change the search terms.',
        access_loaded: 'No accesses',
        logs: 'Sorry, no logs was found. Change the search condition.',
        license_not_found: 'Sorry, no license found. Change the search terms.',
        no_license: 'No licenses are available',
    },

    file_uploads: {
        select_file_tip1: 'Select a file',
        select_file_tip2: 'or drag n drop file here',
        drop_file_note_zip: 'File must have .zip extension',
    },

    video_trim: {
        trim: 'Trim',
        cut_out: 'Cut out',
        apply: 'Apply',
        save: 'Save',
        saving: 'Saving',
        out_no_save: 'Exit without saving',
        save_as_new: 'Save as new video',
        title: 'Title',
        specify_the_name: 'Specify the name',
        room_number: 'Room number',
        record_time: 'Record time',
        confidentially: 'Confidentially',
        cancel: 'Cancel',
        massage: 'Video is too short for editing',
    },

    // Instruction
    instruction_title: 'Employee recognition is disabled',
    instruction_description: 'To enable recognition it is necessary to',
    instruction_step_one: 'Go to',
    instruction_step_two: 'Switch to VPS service configuration"',
    instruction_step_three: 'Select "Recognize employees using GPU" or "Recognize employees using CPU (slow)"',
    instruction_step_four: 'Apply the changes',
    instruction_system_link: 'System',

    errors: {
        access_denied: 'Access denied',
        license_required: 'You do not have a license',
        not_found: 'Page not found',
        filters_same_name: 'Невозможно сохранить фильтры с одинаковым названием',
        no_participant_found: 'No participant found',
        correct_duration: 'Incorrect video duration'
    },

    // Users roles
    roles: 'Roles',
    role: 'Role',
    users: 'Users',
    access_roles: 'Access roles',
    moderators: 'Moderators',

    permissions: {
        'RecorderUserAccount': 'View',
        'RecorderUserAccountCreate': 'Creating',
        'RecorderUserAccountUpdate': 'Update',

        'RecorderAccessGroup': 'View',
        'RecorderAccessGroupCreate': 'Creating',
        'RecorderAccessGroupUpdate': 'Update',
        'RecorderAccessGroupDelete': 'Deleting',

        'RecorderVideo': 'View',
        'RecorderVideoCreate': 'Creating',
        'RecorderVideoUpdate': 'Update',
        'RecorderVideoDelete': 'Deleting',
        'RecorderVideoEditing': 'Editing',
        'RecorderVideoManagePermissions': 'Edit permissions',

        'RecorderVideoEventUpdate': 'Edit protocol',

        group: {
            'RecorderUserAccount': 'Users',
            'RecorderAccessGroup': 'Roles',
            'RecorderVideo': 'Video',
        },
    },

    checkbox_labels: {
        not_automatically_delete: 'Protect from removal',
        indefinitely: 'Indefinitely',
    },
    page_404: {
        title: 'Something went wrong',
        subtitle: 'Page not found'
    },
    branding: {
        color_bg: 'Background color',
        accent_color: 'Accent color',
        color_active_menu_item: 'Color of the active menu item',
        color_plate: 'Plate color',
        color_text: 'Text color',
        no_change_file: 'File not selected',
        load_logo: 'Download logo',
        load_favicon: 'Download favicon',
    },
    time_picker: {
        start: 'Start',
        end: 'End',
        video_duration: 'Video duration'
    },
    licenses: {
        product_id: 'Product Identifier',
        type: 'Type',
        version: 'Version',
        last_day: 'The last day',
    }
};
