
import {defineComponent} from 'vue';
import {mapGetters,} from 'vuex';

export default defineComponent({
    name: 'VideoRowUsersWithAccess',
    props: {
        videoURL: {
            type: String,
            required: false,
        },
        videoGuid: {
            type: String,
            required: false,
        },
        publicAccess: {
            type: Number,
            required: true
        },
        video: {
            type: Object,
            require: true,
        },
        allUser: {
            type: Array,
            require: true,
        },
        usersAccess: {
            type: Object,
            require: true,
        },

    },
    data() {
        return {
            filterStr: '',
            dropdownVisible: false,
            publicity: false,
            accessTypes: [
                {title: this.$t('video_access_types.all_users'), id: 'all_users'},
                {title: this.$t('video_access_types.all_allowed_users'), id: 'allowed_users'},
                {title: this.$t('video_access_types.public'), id: 'public'},
            ],
            changedAccessType: '',
        };
    },
    computed: {
        /* Getters from Vuex */
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),
        selectedAccessType() {
            const accessType = !this.changedAccessType ? this.publicAccess : this.changedAccessType;
            switch (accessType) {
                case 'all_users':
                    return this.$t('video_access_types.all_users');
                case 'allowed_users':
                    return this.$t('video_access_types.all_allowed_users')
                default:
                    return this.$t('video_access_types.public');
            }
        }
    },
    methods: {

        getVideoInstanceFullUrl(id) {
            const path = this.$router.resolve({name: 'videos-instance', params: {id: id}}).href;
            return window.location.origin + path;
        },

        clipboardCopyText() {
            this.$copyText(this.getVideoInstanceFullUrl(this.video.id));
            this.$bvToast.toast(this.$t('alerts_common.linkForTheVideoHaveBeenCopied'), {
                title: this.$t('alerts_common.messageTitle'),
                solid: true
            })
        },

        filteredUsers() {
            if (this.filterStr.trim()) {
                const re = new RegExp(this.filterStr.trim(), 'gi');
                return this.allUser?.filter(u => !u.is_admin && u.login.match(re))
            } else {
                return this.allUser?.filter(u => !u.is_admin);
            }
        },

        /* Send user access of video to server */
        changeUserAccessOfVideo(accessGranted, videoId, userId) {
            this.$emit('changeUserAccessOfVideo', accessGranted, videoId, userId);
        },

        changePublicity(v) {
            this.changedAccessType = v.id;
            this.$emit('changePublicityCallback', this.video.id, v.id);
        },

        toggleDropdown($event) {
            $event.stopPropagation();
            this.dropdownVisible = !this.dropdownVisible;
            var self = this;
            setTimeout(() => {
                if (self.dropdownVisible) {
                    const wh = window.innerHeight || document.documentElement.clientHeight;
                    const popupHeight = self.$refs.dropdownWrap.offsetHeight;
                    const listScrollTop = self.$refs.button.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop;
                    const left = self.$refs.button.parentNode.offsetLeft + self.$refs.button.parentNode.parentNode.parentNode.parentNode.parentNode.offsetLeft;
                    const top = self.$refs.button.parentNode.offsetTop + self.$refs.button.parentNode.parentNode.parentNode.parentNode.parentNode.offsetTop;
                    self.$refs.dropdownWrap.style.left = left + 'px';
                    if (top - listScrollTop + popupHeight > wh - 60) {
                        self.$refs.dropdownWrap.style.top = wh - popupHeight - 60 + 'px';
                    } else {
                        self.$refs.dropdownWrap.style.top = top - listScrollTop + 'px';
                    }
                }
            }, 100);
        },
    }
});
