import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b98f64c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "splitpanes",
  class: "default-theme splitpanes"
}
const _hoisted_2 = {
  key: 0,
  ref: "timelineBlock",
  "data-v-00512ef0": "",
  "data-v-2c69b8da": "",
  class: "timeline-block"
}
const _hoisted_3 = {
  key: 1,
  ref: "splitpanesSkeleton",
  class: "timeline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Player = _resolveComponent("Player")!
  const _component_b_skeleton = _resolveComponent("b-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle([{
                width: `${_ctx.playerWidth}px`,
                top: `${_ctx.playerTop}`
            }, {"height":"320px","position":"relative","z-index":"1000","width":"100%"}]),
      "data-v-2c69b8da": "",
      class: "splitpanes__pane"
    }, [
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Player, {
              "video-player-current-time": _ctx.videoPlayerCurrentTime,
              "video-player-duration": _ctx.videoPlayerDuration,
              "tab-selected": _ctx.tabSelected,
              onEditPersonCallback: _ctx.editPersonCallback,
              onSwitchToVideoTime: _ctx.handleProtocolTimeClick,
              onChangeVideoPlayerCurrentTimeAfterScrollCallback: _ctx.changeVideoPlayerCurrentTimeAfterScrollCallback,
              onChangeCurrentTimeOfTimeline: _ctx.changeCurrentTimeOfTimeline
            }, null, 8, ["video-player-current-time", "video-player-duration", "tab-selected", "onEditPersonCallback", "onSwitchToVideoTime", "onChangeVideoPlayerCurrentTimeAfterScrollCallback", "onChangeCurrentTimeOfTimeline"])
          ], 512))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_b_skeleton, {
              "no-aspect": "",
              height: "100%"
            })
          ], 512))
    ], 4)
  ], 512))
}