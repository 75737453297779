
import {defineComponent} from 'vue'

export default defineComponent({
    name: 'BTooltip',
    props: {
        target: {
            type: String,
            required: true
        },
        triggers: {
            type: String,
            required: true
        },
    },
});
