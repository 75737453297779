function initialState() {
    return {
        currentPageState: 1,
        currentPageSize: 20,
    };
}

const getters = {
    currentPageState(state) {
        return state.currentPageState;
    },
    currentPageSize(state) {
        return state.currentPageSize;
    },
};

const mutations = {
    setCurrentPage(state, payload) {
        state.currentPageState = payload;
    },
    setCurrentPageSize(state, pageSize) {
        // console.log('currentPageSize', pageSize);
        state.currentPageSize = pageSize;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
};
