import {api} from '@app/system/api/api-services';
import {LicenseInput} from '@app/system/api/service-proxies/service-proxies';
import {i18n} from '@app/i18n';

function initialState() {
    return {
        isLoading: false,
        isServerError: false,
        serverErrorMessage: null,
        validLicenses: [],
        uniqueKey: null,
    };
}

const getters = {
    validLicenses(state) {
        console.log('state.validLicenses', state.validLicenses);
        return state.validLicenses
    },
    uniqueKey(state) {
        console.log('state.uniqueKey', state.uniqueKey);
        return state.uniqueKey
    },
    isServerError(state) {
        return state.isServerError;
    },
    serverErrorMessage(state) {
        return state.serverErrorMessage;
    },
    isLoading(state) {
        return state.isLoading;
    },
};

const actions = {
    async getLicenseIdForGeneration({commit}) {
        try {
            const resp = await api.licenseServiceProxy.hwKey('1.0').toPromise();
            commit('setUniqueKey', resp);
        } catch (error) {
            console.log('error', error);
        }
    },
    async getValidLicenses({commit}) {
        commit('setLoading');
        try {
            const resp = await api.licenseServiceProxy.validLicenses('1.0').toPromise();
            commit('setLoaded');
            commit('setValidLicenses', resp);
        } catch (error) {
            commit('setLoaded');
            commit('setServerError', error);
        }
    },
    async addLicense({commit}, license: LicenseInput) {
        commit('setLoading');
        try {
            await api.licenseServiceProxy.import('1.0', license).toPromise();
            commit('setLoaded');
        } catch (error) {
            commit('setLoaded');
            commit('setServerError', error);
        }
    },
    async deleteLicense({commit}, licenseId: string) {
        commit('setLoading');
        try {
            await api.licenseServiceProxy.delete(licenseId,'1.0').toPromise();
            commit('setLoaded');
        } catch (error) {
            commit('setLoaded');
            commit('setServerError', error);
        }
    },
};

const mutations = {
    setLoading(state) {
        state.isLoading = true;
        state.isServerError = false;
        state.serverErrorMessage = null;
    },
    setLoaded(state) {
        state.isLoading = false;
    },
    setServerError(state, err) {
        state.isServerError = true;
        if (err.response && err.response.data && err.response.data.error) {
            state.serverErrorMessage = err.response.data.error;
        } else {
            state.serverErrorMessage = i18n.global.t('unknown_error');
        }
    },
    setValidLicenses(state, licenses) {
        licenses.forEach(license => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(license.data, "text/xml");
            const versionNode = xmlDoc.getElementsByTagName("Version")[0];
            const expiresAfterNode = xmlDoc.getElementsByTagName("ExpiresAfter")[0];
            const version = versionNode ? versionNode.textContent : null;
            const date = expiresAfterNode ? expiresAfterNode.textContent : null;

            license.version = version;
            license.date = date;
        });

        state.validLicenses = licenses
    },

    setUniqueKey(state, license) {
        state.uniqueKey = license
    }
};

/* eslint-enable no-param-reassign */
export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
