function initialState() {
    return {
        fullPath: null,
    };
}

const mutations = {
    setFullPath(state, path) {
        state.fullPath = path;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
};
