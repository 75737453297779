import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-822ec79a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overlay"
}
const _hoisted_2 = {
  key: 0,
  class: "error-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.modalShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_modal, {
      id: "save-filter-modal",
      ref: "modal",
      "modal-show": _ctx.modalShow,
      title: _ctx.$t('modal_titles.save_filter'),
      "ok-title": _ctx.$t('buttons.save'),
      "cancel-title": _ctx.$t('buttons.cancel'),
      "ok-disabled": (_ctx.form.name?.length || 0) < 3,
      onShow: _ctx.resetModal,
      onHidden: _ctx.closeModal,
      onOk: _ctx.submitForm
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_form, {
          onSubmit: _ctx.submitForm,
          onReset: _ctx.resetForm
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_group, {
              label: _ctx.$t('table_titles.name'),
              "label-for": "name-input"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_input, {
                  id: "name-input",
                  modelValue: _ctx.form.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event))
                }, null, 8, ["modelValue"]),
                ((_ctx.form.name?.length || 0) < 3)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Имя фильтра должно содержать 3 и более символов "))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["onSubmit", "onReset"])
      ]),
      _: 1
    }, 8, ["modal-show", "title", "ok-title", "cancel-title", "ok-disabled", "onShow", "onHidden", "onOk"])
  ]))
}