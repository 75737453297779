import {IParticipantDto, IPersonDto} from '@app/system/api/service-proxies/service-proxies';
import {i18n} from '@app/i18n';

export function getTime(value: string): string {
    const sec_num = parseInt(value, 10);
    let hours: string | number = Math.floor(sec_num / 3600);
    let minutes: string | number = Math.floor((sec_num - hours * 3600) / 60);
    let seconds: string | number = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
}

export function parseTimeString(value: string) {
    const a = value.split(':');
    const totalSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

    return {
        totalSeconds,
    };
}

export function getPersonDisplayLabel(data: IParticipantDto | IPersonDto): string {
    let label = data?.fullName;
    if (!label) {
        return i18n.global.t('page_titles.participant_not_recognized');
    }
    const personNumber = getPersonNumber(data);
    if (personNumber) {
        label += ` (${personNumber})`;
    }

    return label;
}

export function getPersonNumber(data: IParticipantDto | IPersonDto | null): string {
    const personNumber = (data as IParticipantDto)?.personNumber || (data as IPersonDto)?.extraProperties?.Number;
    return personNumber;
}

export function deleteModalBackdrop(route = true) {
    const modalBackdrop = document.querySelector<HTMLElement>('.modal-backdrop.show');
    if (route && modalBackdrop) {
        modalBackdrop.remove();
    }
}

export function getWidthScreen() {
    if (window.innerWidth <= 767) {
        return true;
    } else {
        return false;
    }
}
