
import {useVuelidate} from '@vuelidate/core';
import {helpers, minLength, required} from '@vuelidate/validators';
import {defineComponent} from 'vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';

import Loader from '@app/components/ui-kit/molecules/Loader.vue';
import Modal from '@app/components/ui-kit/molecules/Modal.vue';
import {config} from '@app/config';
import {api} from '@app/system/api/api-services';
import {
    ICreatePersonInput,
    IParticipantDto,
    ParticipantDto,
    PersonDto,
    SpecificationParameters,
} from '@app/system/api/service-proxies/service-proxies';
import {deleteModalBackdrop} from '@app/utils/helpers';
import {showToastError} from '@app/system/api/xhr-factory';

const avatarPlaceholderImg = require('@app/assets/icons/avatar_placeholder.png');
const fullNameRegex = /^([a-zA-Zа-яА-ЯёЁ]{1,}-?[a-zA-Zа-яА-ЯёЁ]{1,})? ([a-zA-Zа-яА-ЯёЁ]{1,}-?[a-zA-Zа-яА-ЯёЁ]{1,})?( ([a-zA-Zа-яА-ЯёЁ]{1,}-?[a-zA-Zа-яА-ЯёЁ]{1,})?)?$/;

const fullNameRegexValidatorFunc = helpers.regex(fullNameRegex);

export default defineComponent({
    name: 'EditPersonModal',
    components: {
        Modal,
        Loader,
    },

    inject: ['openEditUserModal'],

    props: [
        'personId',
    ],
    emits: ['personCallback'],

    setup() {
        return {v$: useVuelidate()};
    },
    data() {
        return {
            photoPath: config.photoPath,
            id: 'edit-person-modal',
            entity: null,
            selectedPerson: null,
            personItems: [],
            avatarPlaceholderImg,
            avatarImageObjectURL: null,
            // Form fields
            form: {
                avatarFile: null,
                docNumber: '',
                fullName: '',
                personNumber: '',
                personalData: '',
                isLoading: true,
                id: '',
            },
            emailParticipant: null,
            modalShow: false,
            errorText: '',
            ownerId: null,
        };
    },
    validations() {
        return {
            form: {
                fullName: {required, minLength: minLength(2), fullNameRegexValidatorFunc},
            },
        };
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            userEmail: 'userEmail',
        }),

        ...mapGetters('persons', {
            isLoading: 'isLoading',
            isServerError: 'isServerError',
            serverErrorMessage: 'serverErrorMessage',
        }),

        isSubmitAvailable() {
            if (!this.isGrantedPolicy('Recorder.Participant.Update')) {
                return false;
            }
            return this.form.fullName?.length >= 2 && this.isValidName;
        },

        // Get avatar placeholder or avatar image
        getAvatarImgOrPlaceholder() {
            if (this.avatarImageObjectURL?.src) {
                return this.avatarImageObjectURL.src;
            } else {
                return this.avatarPlaceholderImg;
            }
        },
        isValidName() {
            return fullNameRegex.test(this.form.fullName) && this.form.fullName.length;
        },
    },
    watch: {
        '$route'(to) {
            deleteModalBackdrop(to);
        },
    },
    methods: {
        ...mapActions('persons', {
            getInstance: 'getInstance',
            deletePerson: 'delete',
            savePerson: 'save',
            setIdentified: 'setIdentified',
        }),
        ...mapMutations('persons', {
            setPerson: 'setPerson',
        }),
        ...mapActions('users', {
            getUserData: 'getInstance',
        }),

        show() {
            this.modalShow = true;
            this.selectedPerson = null;
            this.v$.$reset();
        },

        hide() {
            this.modalShow = false;
        },

        // Set avatar after `form.avatarFile` field  change
        async setAvatarImage(event) {
            if (event.target.files.length <= 0) {
                return;
            }

            const file = event.target.files[0];
            if (!file) {
                return;
            }

            this.avatarImageObjectURL = {
                src: URL.createObjectURL(file),
                file: file,
            };
        },

        // Reset modal
        async resetModal() {
            await this.resetForm();
        },

        async closeModal() {
            this.hide();
            this.setPerson(null);
        },

        itemProjection(value: ParticipantDto | PersonDto | null) {
            return value?.fullName || this.form.fullName;
        },

        async selectItemEventHandler(item: ParticipantDto | PersonDto | null) {
            if (!item?.fullName) {
                return;
            }
            this.form.fullName = item.fullName;
            this.selectedPerson = item;
            if (this.selectedPerson && typeof this.selectedPerson === 'object') {
                if ((this.selectedPerson as ParticipantDto).docNumber) {
                    this.form.docNumber = this.selectedPerson.docNumber;
                    this.form.personNumber = this.selectedPerson.personNumber;
                }
            }

            const filterLikeFullName = new SpecificationParameters({
                specId: '3423EE75-F0EE-4E00-8980-F3727DBF3ABA',
                parameters: {value: item.fullName},
            });

            const specifications = [
                filterLikeFullName,
            ];

            return api.personServiceProxy.page(1, 100, false, '1.0', specifications)
                .toPromise()
                .then(resp => {
                    this.personItems = resp.data.map(item => item).slice(0, 5);
                }).catch(e => {
                    console.log(e);
                });
        },

        async onInputEventHandler(value) {
            this.selectedPerson = null;
            this.entity.personId = null;
            this.form.fullName = value.input?.trim();

            const filterLikeFullName = new SpecificationParameters({
                specId: '3423EE75-F0EE-4E00-8980-F3727DBF3ABA',
                parameters: {value: value.input},
            });

            const specifications = [
                filterLikeFullName,
            ];

            return api.personServiceProxy.page(1, 100, false, '1.0', specifications)
                .toPromise()
                .then(resp => {
                    this.personItems = resp.data.map(item => item).slice(0, 5);
                }).catch(e => {
                    console.log(e);
                });
        },

        // Attempt to create user
        async submitForm() {
            const entity: ParticipantDto = {
                ...this.entity,
                id: this.form.id,
                fullName: this.form.fullName,
                description: this.form.personalData,
                primaryPhoto: null,
                isPrimary: true,
            };

            delete entity?.photos;

            if (this.selectedPerson?.id) {
                if (this.selectedPerson.docNumber) {
                    entity.personId = this.selectedPerson.personId;
                } else {
                    entity.personId = this.selectedPerson.id;
                }
            } else if (!this.selectedPerson && !entity.personId && entity.fullName) {
                entity.isPrimary = true;

                const names = this.form.fullName.split(' ');

                const personInput: ICreatePersonInput = {
                    ownerId: undefined,
                    fullName: this.form.fullName,
                    lastName: names[0] || '',
                    firstName: names[1] || '',
                    middleName: names[2] || '',
                    nickname: undefined,
                    birthDate: undefined,
                    notes: this.form.personalData,
                    avatarFileId: undefined,
                    identities: undefined,
                    contacts: undefined,
                };
                const resp = await api.accountServiceProxy.createPerson('1.0', personInput as any).toPromise();

                entity.personId = resp.id;
            }

            const participantResp: ParticipantDto = await this.savePerson(entity);

            if (entity.state != 20 && (entity.personId || entity.fullName)) {
                await this.setIdentified({
                    id: participantResp.id,
                    ...entity,
                });
            }

            this.$emit('personCallback');
            URL.revokeObjectURL(this.avatarImageObjectURL);
            this.hide();
        },

        async resetForm() {
            this.form.isLoading = true;

            this.getInstance(this.personId)
                .then((resp: IParticipantDto) => {
                    this.avatarImageObjectURL = {
                        src: resp.photos?.[0]?.url,
                        file: null,
                    };
                    if (resp?.contacts && resp.contacts.length > 0) {
                        this.emailParticipant = resp.contacts[0]?.data;
                    } else {
                        this.emailParticipant = null;
                    }
                    this.form = {
                        avatarFile: null,
                        docNumber: resp.docNumber,
                        fullName: resp.fullName,
                        personNumber: resp.personNumber,
                        personalData: resp.description,
                        isLoading: false,
                        id: resp.id,
                    };
                    this.personItems = [resp];
                    this.entity = resp;
                    this.ownerId = resp.ownerId;

                    setTimeout(() => {
                        this.$refs.typeahead.getInput().value = resp.fullName || '';
                    }, 200);
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    this.form.isLoading = false;
                });
        },

        async openCardUser() {
            let ownerId = this.selectedPerson?.ownerId || this.ownerId || this.entity?.ownerId;
            const personId = this.selectedPerson?.personId || this.entity?.personId;
            try {
                this.form.isLoading = true;
                if (!ownerId && personId) {
                    const person = await api.personServiceProxy.get(personId, '1.0').toPromise();
                    if (person.ownerId) {
                        ownerId = person.ownerId;
                    }
                }

                if (!ownerId) {
                    showToastError('Не найден пользователь-владелец персоны');
                    this.form.isLoading = false;
                    return;
                }

                const userData = await this.getUserData(ownerId);

                this.form.isLoading = false;

                this.hide();
                this.openEditUserModal(userData);
            } catch (e) {
                console.error(e);
                this.form.isLoading = false;
            }
        },
    },
});
