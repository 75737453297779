
import Loader from '@app/components/ui-kit/molecules/Loader.vue';

export default {
    name: 'Dialog',
    components: {Loader},
    inject: ['isDelete'],
    props: {
        modalShow: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            required: false,
            default: 700,
        },
        persistent: {
            type: Boolean,
            required: false,
            default: false,
        },
        okTitle: {
            type: String,
            required: false,
            default: 'OK',
        },
        cancelTitle: {
            type: String,
            required: false,
            default: 'Cancel',
        },
        okDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        withFooter: {
            type: Boolean,
            required: false,
            default: true,
        },
        withCancel: {
            type: Boolean,
            required: false,
            default: true,
        },
        isOverflowVisible: {
            type: Boolean,
            default: false,
        },
        isAdditionalButton: {
            type: Boolean,
            default: false,
        },
        isDelete: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        modalShow(value) {
            if (value) {
                this.$emit('show');
            }
        },
    },

    methods: {
        onClickOk() {
            this.$emit('ok');
        },

        onClickCancel() {
            this.$emit('hidden');
        },
    },
};
