import {config} from '@app/config';
import {i18n} from '@app/i18n';

export enum FilterOperatorTypeEx {
    Equals = 0,
    NotEquals = 1,
    GreaterThan = 2,
    LessThan = 3,
    LessThanOrEqual = 4,
    GreaterThanOrEqual = 5,
    Contains = 6,
    NotContains = 7,
    Period = 8,
    DeletionProtection = 9,
    NotDeletionProtection = 10,
    Interval = 11,
    Duration = 12,
    Confidentially = 13,
    NotConfidential = 14,
}

export const optionEqual = {type: FilterOperatorTypeEx.Equals, title: i18n.global.t('dropdown_filter.equal')};
export const optionNotEqual = {type: FilterOperatorTypeEx.NotEquals, title: i18n.global.t('dropdown_filter.not_equal')};
export const optionBefore = {type: FilterOperatorTypeEx.LessThan, title: i18n.global.t('dropdown_filter.before')};
export const optionAfter = {type: FilterOperatorTypeEx.GreaterThan, title: i18n.global.t('dropdown_filter.after')};
export const optionContain = {type: FilterOperatorTypeEx.Contains, title: i18n.global.t('dropdown_filter.contain')};
export const optionPeriod = {type: FilterOperatorTypeEx.Period, title: i18n.global.t('dropdown_filter.period')};

export const optionDeletionProtection = {type: FilterOperatorTypeEx.DeletionProtection, title: i18n.global.t('dropdown_filter.yes')};
export const optionNotDeletionProtection = {type: FilterOperatorTypeEx.NotDeletionProtection, title: i18n.global.t('dropdown_filter.no')};
export const optionInterval = {type: FilterOperatorTypeEx.Interval, title: i18n.global.t('dropdown_filter.interval')};
export const optionDuration = {type: FilterOperatorTypeEx.Duration, title: i18n.global.t('dropdown_filter.duration')};
export const optionConfidentially = {type: FilterOperatorTypeEx.Confidentially, title: i18n.global.t('dropdown_filter.yes')};
export const optionNotConfidential = {type: FilterOperatorTypeEx.NotConfidential, title: i18n.global.t('dropdown_filter.no')};

export const optionNotContain = {
    type: FilterOperatorTypeEx.NotContains,
    title: i18n.global.t('dropdown_filter.not_contain'),
};

export const optionAccessGranted = {type: 'access_granted', title: i18n.global.t('dropdown_filter.access_granted')};
export const optionAccessNotGranted = {
    type: 'access_not_granted',
    title: i18n.global.t('dropdown_filter.access_not_granted'),
};

export const optionPresent = {type: FilterOperatorTypeEx.Equals, title: i18n.global.t('dropdown_filter.present')};
export const optionNotPresent = {
    type: FilterOperatorTypeEx.NotEquals,
    title: i18n.global.t('dropdown_filter.not_present'),
};

export const optionModerates = {type: FilterOperatorTypeEx.Equals, title: i18n.global.t('dropdown_filter.moderates')};
export const optionNotModerates = {
    type: FilterOperatorTypeEx.NotEquals,
    title: i18n.global.t('dropdown_filter.not_moderates'),
};

export const optionThereIs = {type: FilterOperatorTypeEx.Contains, title: i18n.global.t('dropdown_filter.there_is')};
export const optionThereNotIs = {
    type: FilterOperatorTypeEx.NotContains,
    title: i18n.global.t('dropdown_filter.there_not_is'),
};

export const optionAnd = {type: 'and', title: i18n.global.t('dropdown_filter.and')};
export const optionOr = {type: 'or', title: i18n.global.t('dropdown_filter.or')};

export function isNot(value: FilterOperatorTypeEx): boolean {
    return [
        FilterOperatorTypeEx.NotEquals,
        FilterOperatorTypeEx.NotContains,
    ].some(v => v == value);
}

export const fieldDate = {
    type: 'date',
    specIds: [
        '55F14C77-E802-411D-B224-F12418E6279C',
        'A68954BE-E81D-4551-BF0D-65CAF98143AC',
        'F61B3E08-E4B8-408B-8C5F-1AFE1FAF74C8',
    ],
    propertyName: 'DocDate',
    title: i18n.global.t('dropdown_filter.date'),
    options: [
        optionEqual,
        optionNotEqual,
        optionBefore,
        optionAfter,
        optionPeriod
    ],
};

export const fieldName = {
    type: 'name',
    specIds: ['05CE7473-050D-434A-B244-C4B4E4BE1271'],
    title: i18n.global.t('dropdown_filter.name'),
    options: [
        // optionEqual,
        // optionNotEqual,
        optionContain,
        optionNotContain,
    ],
};

export const fieldConference = {
    type: 'conference',
    specIds: ['5FC9E8DE-C830-4D83-95B2-4666F55FDA34'],
    title: i18n.global.t('dropdown_filter.conference_name'),
    options: [
        // optionEqual,
        // optionNotEqual,
        optionContain,
        optionNotContain,
    ],
};

export const fieldDescription= {
    type: 'description',
    specIds: ['05CE7473-050D-434A-B244-C4B4E4BE1271'],
    title: i18n.global.t('dropdown_filter.video_name'),
    options: [
        // optionEqual,
        // optionNotEqual,
        optionContain,
        optionNotContain,
    ],
};

export const fieldStaff = {
    type: 'staff',
    specIds: [],
    title: i18n.global.t('dropdown_filter.staff'),
    options: [
        optionPresent,
        optionNotPresent,
    ],
};

export const fieldModerator = {
    type: 'moderator',
    specIds: ['0B541CA6-986E-478F-B759-FA049B375D8E'],
    title: i18n.global.t('dropdown_filter.moderator'),
    options: [
        optionModerates,
        optionNotModerates,
    ],
};

export const fieldDeletionProtection = {
    type: 'deletionProtection',
    specIds: [],
    title: i18n.global.t('dropdown_filter.deletion_protection'),
    options: [
        optionDeletionProtection,
        optionNotDeletionProtection,
    ],
};

export const fieldConfidentially = {
    type: 'confidentially',
    specIds: [],
    title: i18n.global.t('dropdown_filter.confidentially'),
    options: [
        optionConfidentially,
        optionNotConfidential,
    ],
};

export const fieldRoomNumber = {
    type: 'roomNumber',
    specIds: [],
    title: i18n.global.t('dropdown_filter.roomNumber'),
    options: [
        optionEqual,
        optionNotEqual,
    ],
};

export const fieldDuration = {
    type: 'duration',
    specIds: [],
    title: i18n.global.t('dropdown_filter.duration'),
    options: [
        optionInterval,
        optionDuration
    ],
};

export const fieldAccess = {
    type: 'users',
    title: i18n.global.t('dropdown_filter.access'),
    options: [
        optionAccessGranted,
        optionAccessNotGranted,
    ],
};

export const fieldPublicLink = {
    type: 'access',
    title: i18n.global.t('dropdown_filter.public_link'),
    options: [
        optionThereIs,
        optionThereNotIs,
    ],
};

// Object key must match with field.type value
export const fields = {
    date: fieldDate,
    name: fieldName,
    conference: fieldConference,
    description: fieldDescription,
};

export const fieldsServerMatrix = {
    DocDate: fields.date,
};

export const fieldsDropdownFilter = {
    date: fieldDate,
    name: fieldName,
};

export const fieldsAccessGroupFilter = {
    date: fieldDate,
    description: fieldDescription,
};

if (config.recognitionMode) {
    (fields as any).staff = fieldStaff;
    (fields as any).moderator = fieldModerator;
    (fields as any).duration = fieldDuration;
    (fields as any).roomNumber = fieldRoomNumber;
    (fields as any).confidentially = fieldConfidentially;
    (fields as any).deletionProtection = fieldDeletionProtection;

    (fieldsDropdownFilter as any).staff = fieldStaff;
    (fieldsDropdownFilter as any).moderator = fieldModerator;
    (fieldsDropdownFilter as any).duration = fieldDuration;
    (fieldsDropdownFilter as any).roomNumber = fieldRoomNumber;
    (fieldsDropdownFilter as any).confidentially = fieldConfidentially;
    (fieldsDropdownFilter as any).deletionProtection = fieldDeletionProtection;

    (fieldsAccessGroupFilter as any).staff = fieldStaff;
    (fieldsAccessGroupFilter as any).moderator = fieldModerator;
    (fieldsAccessGroupFilter as any).roomNumber = fieldRoomNumber;
    (fieldsAccessGroupFilter as any).confidentially = fieldConfidentially;
}
