
import {api} from "@app/system/api/api-services";

import {defineComponent} from 'vue';

import {IFilterSetDto} from '@app/system/api/service-proxies/service-proxies';
import Modal from "@app/components/ui-kit/molecules/Modal.vue";
import {showToastError} from "@app/system/api/xhr-factory";

export default defineComponent({
    name: 'EditFilterItemsModal',
    components: {
        Modal,
    },
    props: {
        filterItems: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            id: 'EditFilterItemsModal',
            items: this.filterItems as IFilterSetDto[],
            namesData: {},
            forDeleteIds: [] as number[],
            modalShow: false,
            serverErrorMessage: null,
        }
    },
    computed: {
        isSubmitDisabled() {
            for (const item of this.items) {
                if ((this.namesData[item.id]?.length || 0) < 3) {
                    return true;
                }
            }
            return false;
        },
    },
    created() {
        this.makeData();
    },
    methods: {
        makeData() {
            this.items.forEach(item => {
                this.namesData[item.id] = item.title;
            });
        },

        updateNames() {
            this.items = this.items.map(item => ({
                ...item,
                title: this.namesData[item.id],
                isEdited: item.title !== this.namesData[item.id],
            }));
        },

        show() {
            this.modalShow = true;
        },

        hide() {
            this.modalShow = false;
        },

        async submitForm() {
            for (const item of this.items) {
                if ((this.namesData[item.id]?.length || 0) < 3) {
                    return false;
                }
                if (Object.values(this.namesData).filter(title => title === this.namesData[item.id])?.length > 1) {
                    showToastError(this.$t('errors.filters_same_name'));
                    return false;
                }
            }

            this.updateNames();

            const requests: Promise<any>[] = []

            this.items.filter(item => item.isEdited).forEach((item: IFilterSetDto) => {
                const filterDto: IFilterSetDto = {
                    ...item,
                };

                requests.push(api.filterSetServiceProxy.savePost('1.0', filterDto as any).toPromise());
            });

            this.forDeleteIds.forEach(id => {
                requests.push(api.filterSetServiceProxy.deleteDelete(id, '1.0').toPromise());
            })

            Promise.all(requests).then(() => {
                location.reload();
            }).catch(e => {
                console.error(e);
            });
        },

        async resetModal() {
            this.items = this.filterItems;
            this.namesData = [];
            this.forDeleteIds = [];

            this.serverErrorMessage = null;

            this.makeData();
        },

        async closeModal() {
            this.hide();
        },

        // eslint-disable-next-line no-unused-vars
        onInputName(item: IFilterSetDto, title: string): void {
            this.serverErrorMessage = null;
        },

        onClickDeleteItem(item: IFilterSetDto): void {
            this.items = this.items.filter(value => value.title !== item.title);
            this.forDeleteIds.push(item.id);
        }
    }
});
