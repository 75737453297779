export function validationPassword(password, locale) {
    if (password?.length < 8 && locale === 'en') {
        return [false, 'At least 8 characters'];
    } else if (password?.length < 8 && locale === 'ru') {
        return [false, 'Не менее 8 символов'];
    }
    if (password.search(/[a-z]/) === -1 && locale === 'en') {
        return [false, 'Lowercase letters (a to z)'];
    } else if (password.search(/[a-z]/) === -1 && locale === 'ru') {
        return [false, 'Буквы нижнего регистра (от a до z)'];
    }
    if (password.search(/[A-Z]/) === -1 && locale === 'en') {
        return [false, 'Upper case letters (A to Z)'];
    } else if (password.search(/[A-Z]/) === -1 && locale === 'ru') {
        return [false, 'Буквы верхнего регистра (от A до Z)'];
    }
    if (password.search(/\d/) === -1 && locale === 'en') {
        return [false, 'Numbers (0 to 9)'];
    } else if (password.search(/\d/) === -1 && locale === 'ru') {
        return [false, 'Цифры (от 0 до 9)'];
    }
    if (password.search(/[!@#$%^&*()\-=_+~[\]{}'"\\|,./<>?]/) === -1 && locale === 'en') {
        return [false, 'Special characters (for example: !, $, #, %)'];
    } else if (password.search(/[!@#$%^&*()\-=_+~[\]{}'"\\|,./<>?]/) === -1 && locale === 'ru') {
        return [false, 'Спецсимволы (например: !, $, #, %)'];
    }
    return true;
}

export function cyrillicDisable(e) {
    const re = '/а|б|в|г|д|е|ё|ж|з|и|Й|к|л|м|н|о|п|р|с|т|у|ф|х|ц|ч|ш|щ|ъ|ы|ь|э|ю|я/';
    if (re.toLowerCase().indexOf(e.key) != -1 || re.toUpperCase().indexOf(e.key) != -1) {
        e.preventDefault();
    }
}

export function validateEmail(e) {
    if (e?.search(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/) ===
        -1) {
        return false;
    } else if (e?.search(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/) ===
        0) {
        return true;
    }
}


