
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
    name: 'VideoInstanceMenu',
    props: {
        video: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            dropdownVisible: false,
        };
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isAdmin: 'isAdmin',
            userEmail: 'userEmail',
        }),
    },

    methods: {

        isModerator(video) {
            return video?.read_only === false;
        },

        removeVideo($event) {
            this.$emit('removeVideo', $event, this.video);
            this.toggleDropdown();
        },

        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
            var self = this;
            setTimeout(() => {
                if (self.dropdownVisible) {
                    const wh = window.innerHeight || document.documentElement.clientHeight;
                    const popupHeight = self.$refs.dropdownWrap.offsetHeight;
                    const listScrollTop = self.$refs.button.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop;
                    const left = self.$refs.button.parentNode.offsetLeft + self.$refs.button.parentNode.parentNode.parentNode.parentNode.parentNode.offsetLeft;
                    const top = self.$refs.button.parentNode.offsetTop + self.$refs.button.parentNode.parentNode.parentNode.parentNode.parentNode.offsetTop;
                    self.$refs.dropdownWrap.style.left = left + 'px';
                    if (top - listScrollTop + popupHeight > wh - 60) {
                        self.$refs.dropdownWrap.style.top = wh - popupHeight - 60 + 'px';
                    } else {
                        self.$refs.dropdownWrap.style.top = top - listScrollTop + 'px';
                    }
                }
            }, 100);
        },

    }
});
