import {config} from '@app/config';
import {axiosVinteoRecordApi} from '@app/system/api/old';
import {
    IApplicationAuthConfigurationDto,
    IPermissionGrantInfoDto,
} from '@app/system/api/service-proxies/service-proxies';
import {getJwtPayload} from '@app/utils/jwt';

function initialState() {
    return {
        isLoading: false,
        isServerError: false,
        serverErrorMessage: null,
    };
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },

    isServerError(state) {
        return state.isServerError;
    },

    serverErrorMessage(state) {
        return state.serverErrorMessage;
    },

    jwt: () => () => {
        const $oidc = window['$oidc'];
        return $oidc.accessToken;
    },

    userEmail: () => () => {
        const $oidc = window['$oidc'];
        const jwt = $oidc.accessToken || null;
        if (!jwt) {
            return null;
        }

        // Check by exist key 'user_email'
        const jwtPayload = JSON.parse(getJwtPayload());

        return jwtPayload.email || '';
    },

    isAdmin: () => () => isAdmin(),

    isGrantedPolicy: () => (
        name: string,
        isLikeSubstring: boolean = false,
        permissions: IPermissionGrantInfoDto[] = [],
    ) => {
        return isGrantedPolicy(name, isLikeSubstring, permissions);
    },

    isGrantedPolicyForSome: () => (
        names: string[],
        isLikeSubstring: boolean = false,
        permissions: IPermissionGrantInfoDto[] = [],
    ) => {
        return names.some(name => isGrantedPolicy(name, isLikeSubstring, permissions));
    },
};

const actions = {
    signInAttempt({commit}, payload) {
        commit('setLoading');
        const urlSegment = 'auth/login';
        return new Promise((resolve, reject) => {
            axiosVinteoRecordApi.post(urlSegment, payload)
                .then(resp => {
                    commit('setLoaded');
                    // Set JWT
                    localStorage.setItem('jwt', resp.data.token);
                    resolve(resp);
                })
                .catch(err => {
                    commit('setLoaded');
                    commit('setServerError', err.response.data.error);
                    reject(err);
                });
        });
    },

    // identity server...
    isSignInAttempt({commit}) {
        commit('setLoading');
        const urlSegment = 'auth/login';
        return new Promise((resolve, reject) => {
            axiosVinteoRecordApi.post(urlSegment, {})
                .then(resp => {
                    commit('setLoaded');
                    // Set JWT
                    localStorage.setItem('jwt', resp.data.token);
                    resolve(resp);
                })
                .catch(err => {
                    commit('setLoaded');
                    commit('setServerError', err.response.data.error);
                    reject(err);
                });
        });
    },

    /* Attempt to Sign out */
    // signOut({ commit }) {
    signOut() {
        // Delete JWT

        localStorage.removeItem('jwt');
        if (config.identityServerOauth) {
            location.href = config.baseUrl + '/' + config.apiVersionStr + '/auth/signout-oidc';
        }

        // Backend will add JWT to blacklist
        // commit('setLoading');
        // const urlSegment = 'auth/logout';
        // return new Promise((resolve, reject) => {
        //   axiosVinteoRecordApi.get(urlSegment)
        //     .then(resp => {
        //       commit('setLoaded');
        //       resolve(resp)
        //     })
        //     .catch(err => {
        //       commit('setLoaded');
        //       reject(err)
        //     })
        // })
    },
};

const mutations = {
    setLoading(state) {
        state.isLoading = true;
        state.isServerError = false;
        state.serverErrorMessage = null;
    },

    setLoaded(state) {
        state.isLoading = false;
    },

    setServerError(state, errorMessage) {
        state.isServerError = true;
        state.serverErrorMessage = errorMessage;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};

export function isGrantedPolicy(
    name: string,
    isLikeSubstring: boolean = false,
    permissions: IPermissionGrantInfoDto[] = [],
): boolean {
    const $authConfig: IApplicationAuthConfigurationDto = window['$authConfig'];
    const grantedPolicies = {
        ...($authConfig?.grantedPolicies || {}),
        ...permissions.reduce((acc, permission) => {
            if (permission.isGranted) {
                acc[permission.name] = true;
            }
            return acc;
        }, {}),
    };

    // console.log('permissions ###', permissions);

    if (!grantedPolicies) {
        return false;
    }

    if (grantedPolicies[name]) {
        return true;
    }

    if (isLikeSubstring) {
        for (const prop in grantedPolicies) {
            if (prop.includes(name)) {
                return true;
            }
        }
    }

    return false;
}

export function isAdmin() {
    const $oidc = window['$oidc'];
    const jwt = $oidc.accessToken || null;
    if (!jwt) {
        return null;
    }

    const jwtPayload = JSON.parse(getJwtPayload());

    return jwtPayload.role.indexOf('admin') >= 0;
}
