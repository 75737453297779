
import {defineComponent} from 'vue';

import Modal from "@app/components/ui-kit/molecules/Modal.vue";

export default defineComponent({
    name: 'SaveFilterModal',
    components: {
        Modal,
    },
    props: {
        video: {
            type: Object,
            required: true,
        },
        serverErrorMessage: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            id: 'save-filter-modal',
            form: {
                name: '',
            },
            modalShow: false,
        }
    },
    methods: {
        show() {
            this.modalShow = true;
        },
        hide() {
            this.modalShow = false;
        },

        async submitForm() {
            if ((this.form.name?.length || 0) < 3) {
                return false;
            }

            const {name} = this.form;

            this.$emit('saveCallback', name);
        },

        async resetForm() {
            this.form = {
                name: '',
            };
        },
        async resetModal() {
            await this.resetForm();
        },

        async closeModal() {
            this.hide();
        },
    }
});
