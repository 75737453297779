
import {defineComponent} from 'vue';
import {mapActions, mapGetters} from 'vuex';

import {config} from '@app/config';
import {resetVideoList} from '@app/modules/video/pages/VideosPage.vue';

import LanguageChanger from './LanguageChanger.vue';

export default defineComponent({
    name: 'Sidebar',
    components: {
        LanguageChanger,
    },
    data() {
        return {
            config,
            recognitionMode: config.recognitionMode,
        }
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isAdmin: 'isAdmin',
            userEmail: 'userEmail',
        }),
        ...mapGetters('settings', {
            isMlEnabled: 'isMlEnabled'
        }),
        ...mapGetters('brandingSettings', {
            logo: 'logo',
        })
    },
    methods: {
        /* Methods from Vuex */
        ...mapActions('auth', {
            signOut: 'signOut',
        }),

        clearVideoFilter() {
            try {
                if (resetVideoList) {
                    resetVideoList();
                }
            } catch (err) {
                console.log('Video list clear filter error');
            }
            return false;
        },

        /* Sign Out */
        async signOutWrapper() {
            await this.signOut();
            await this.$router.push({name: 'logout'});
        },

    },
});
