import {i18n} from '@app/i18n';

import {
    ParticipantDto,
    ParticipantState,
    SpecificationParameters,
} from '@app/system/api/service-proxies/service-proxies';
import {api} from '@app/system/api/api-services';

function initialState() {
    return {
        isLoading: false,
        isServerError: false,
        serverErrorMessage: null,
        persons: [],
        person: null,
        allPersons: [],

        personsCount: 0,
        perPage: 10,

        currentPageUnrecognized: 1,
        currentPageRecognized: 1,

        status: null,
        name: null,

        searchStr: '',
    };
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    isServerError(state) {
        return state.isServerError;
    },
    serverErrorMessage(state) {
        return state.serverErrorMessage;
    },
    persons(state) {
        return state.persons;
    },
    allPersons(state) {
        return state.allPersons;
    },
    status(state) {
        return state.status;
    },
    personsCount(state) {
        return state.personsCount;
    },
    perPage(state) {
        return state.perPage;
    },
    currentPageUnrecognized(state) {
        return state.currentPageUnrecognized;
    },
    currentPageRecognized(state) {
        return state.currentPageRecognized;
    },
    person(state) {
        return state.person
    }
};

const actions = {
    async getAllList({commit}, payload) {
        const response = await api.personServiceProxy.page(1, 1000, true, '1.0', [])
            .toPromise()
            .then(resp => {
                commit('setLoaded');
                commit('setAllPersons', resp.data);
                return resp.data;
            })
            .catch(err => {
                commit('setLoaded');
                commit('setServerError', err);
                return [];
            });
        return response;
    },

    getUnrecognizedPersons({commit, state}) {
        commit('setLoading');

        const pageSize = state.perPage;
        const pageNumber = state.currentPageUnrecognized;

        const statusParam: ParticipantState = ParticipantState._0;

        const includePhotos = new SpecificationParameters({
            specId: '1252B2B9-DD58-45F7-A437-9F07F1B7B452',
            parameters: null,
        });

        const filterByState = new SpecificationParameters({
            specId: 'F8513C72-62F2-42CC-BAF0-3E38C941F710',
            parameters: {value: statusParam},
        });

        const specifications = [
            includePhotos,
            filterByState,
        ];

        return api.participantsServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications)
            .toPromise()
            .then(resp => {
                    commit('setLoaded');
                    commit('setPersons', resp.data);
                    commit('setPersonsCount', resp.totalCount);
                },
            )
            .catch((err) => {
                commit('setLoaded');
                commit('setServerError', err);
            });
    },

    getRecognizedPersons({commit, state}, payload) {
        commit('setLoading');

        const pageSize = state.perPage;
        const pageNumber = state.currentPageRecognized;

        // const status = state.status;

        const statusParam: ParticipantState = ParticipantState._20;
        const searchStr = state.searchStr;

        const includePhotos = new SpecificationParameters({
            specId: '1252B2B9-DD58-45F7-A437-9F07F1B7B452',
            parameters: null,
        });

        const filterByPrimary = new SpecificationParameters({
            specId: 'B7E4B2B9-7BEF-4BD2-9DC4-8FAA911A9AF9',
            parameters: {value: true},
        });

        const filterByState = new SpecificationParameters({
            specId: 'F8513C72-62F2-42CC-BAF0-3E38C941F710',
            parameters: {value: statusParam},
        });

        const filterLikeFullName = new SpecificationParameters({
            specId: '562FFEAB-9F50-433F-BC9E-BEE60EA4071E',
            parameters: {value: `%${searchStr}%`},
        });

        /*const includeContacts  = new SpecificationParameters({
            specId: '8CAAFABA-ADE5-4B1E-A305-19F7DB0B33FB',
            parameters: null,
        });*/

        const specifications = [
            includePhotos,
            filterByPrimary,
            filterByState,
            // includeContacts
        ];

        if (searchStr) {
            specifications.push(filterLikeFullName);
        }

        return api.participantsServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications)
            .toPromise()
            .then(resp => {
                // console.log('resp getRecognizedPersons', resp);
                    // const participants = resp.data.filter(participant => participant.isPrimary || participant.ownerId);
                    commit('setLoaded');
                    commit('setPersons', resp.data);
                    commit('setPersonsCount', resp.totalCount);
                },
            )
            .catch((err) => {
                commit('setLoaded');
                commit('setServerError', err);
            });
    },

    getInstance({commit}, id) {
        // commit('setLoadedInstance');

        const includePhotos = new SpecificationParameters({
            specId: '1252B2B9-DD58-45F7-A437-9F07F1B7B452',
            parameters: null,
        });

        const includeContacts  = new SpecificationParameters({
            specId: '8CAAFABA-ADE5-4B1E-A305-19F7DB0B33FB',
            parameters: null,
        });

        const specifications = [
            includePhotos,
            includeContacts
        ];

        return new Promise((resolve, reject) => {
            api.participantsServiceProxy.getInclude(id, '1.0', specifications).toPromise()
                .then(resp => {
                    console.log('resp', resp);
                        // commit('setLoadedInstance');
                        commit('setPerson', resp);
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    // commit('setLoadedInstance');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },

    /* Change person data */
    save({commit}, data): Promise<ParticipantDto> {
        commit('setLoading');

        return new Promise((resolve, reject) => {
            api.participantsServiceProxy.save('1.0', {...data} as any).toPromise()
                .then(resp => {
                        commit('setLoaded');
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    commit('setLoaded');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },

    delete({commit}, id) {
        commit('setLoading');

        return new Promise((resolve, reject) => {
            api.participantsServiceProxy.delete(id, '1.0').toPromise()
                .then(resp => {
                        commit('setLoaded');
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    commit('setLoaded');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },

    add({commit}, payload) {
        commit('setLoading');

        return new Promise((resolve, reject) => {
            api.participantsServiceProxy.create('1.0', payload).toPromise()
                .then(resp => {
                        commit('setLoaded');
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    commit('setLoaded');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },

    setIdentified({commit}, data) {
        commit('setLoading');

        return new Promise((resolve, reject) => {
            api.participantsServiceProxy.setIdentified('1.0', {...data} as ParticipantDto).toPromise()
                .then(resp => {
                        commit('setLoaded');
                        commit('setIdentified', data);
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    commit('setLoaded');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },

    getPersonByOwnerId({commit}, id) {
        return new Promise((resolve, reject) => {
            api.participantsServiceProxy.findByOwnerId(id,'1.0').toPromise()
                .then(resp => resolve(resp))
                .catch((err) => {
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },
};

/* eslint-disable no-param-reassign */
const mutations = {
    setLoading(state) {
        state.isLoading = true;
        state.isServerError = false;
        state.serverErrorMessage = null;
    },

    setLoaded(state) {
        state.isLoading = false;
    },

    setServerError(state, err) {
        state.isServerError = true;
        if (err.response && err.response.data && err.response.data.error) {
            state.serverErrorMessage = err.response.data.error;
        } else {
            state.serverErrorMessage = i18n.global.t('unknown_error');
        }
    },

    setAllPersons(state, persons) {
        state.allPersons = persons;
    },

    setPersons(state, persons) {
        /*
          Expect array of objects with fields:
          - id: <int>
          - name: <str>
          - personal_data: <str>
          - avatar: <abs url>
          - videos_count: <int>
        */
        state.persons = persons;
    },

    setPersonsCount(state, count) {
        state.personsCount = count;
    },

    setIdentified(state, person) {
        state.persons = state.persons.map(value => {
            if (value.id === person.id) {
                return {...person, status: ParticipantState._20};
            }
            return person;
        });
    },

    setUnrecognizedPaginationInfo(state, paginationInfo) {
        state.perPage = paginationInfo.perPage;
        state.currentPageUnrecognized = paginationInfo.currentPage;
        // state.status = paginationInfo.status;
    },

    setRecognizedPaginationInfo(state, paginationInfo) {
        state.perPage = paginationInfo.perPage;
        state.currentPageRecognized = paginationInfo.currentPage;
    },

    setStatus(state, status) {
        state.status = status;
    },
    setSearchStr(state, searchStr) {
        state.searchStr = searchStr;
    },

    resetSearchStr(state) {
        state.searchStr = '';
    },
    setPerson(state, person) {
        state.person = person
    }
};
/* eslint-enable no-param-reassign */

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
