
import {defineComponent} from 'vue';
import {mapActions, mapGetters, mapState,} from 'vuex';

import {IVideoEventDto, ParticipantDto} from '@app/system/api/service-proxies/service-proxies';
import {config} from '@app/config';
import {getPersonDisplayLabel, getTime, parseTimeString} from '@app/utils/helpers';

const avatarPlaceholderImg = require('@app/assets/icons/avatar_placeholder.png');

export default defineComponent({
    name: 'Player',
    props: {
        videoPlayerCurrentTime: {
            type: Number,
            require: true,
            default: 0,
        },
        videoPlayerDuration: {
            type: Number,
            require: true,
            default: 0,
        },
        tabSelected: {
            type: Number,
            default: 0,
        }
    },
    emits: [
        'editPersonCallback',
        'switchToVideoTime',
        'changeVideoPlayerCurrentTimeAfterScrollCallback',
        'changeCurrentTimeOfTimeline'
    ],
    data() {
        return {
            photoPath: config.photoPath,
            avatarPlaceholderImg,

            // One second to pixels (1 second = 20px)
            oneSecondToPixels: 20,
            // Video current time for scroll event listener
            videoPlayerCurrentTimeScroll: 0,

            // Scale options
            scaleX: config.videoTimelineScale.scaleX,
            minScaleX: config.videoTimelineScale.minScaleX,
            maxScaleX: config.videoTimelineScale.maxScaleX,
            stepScaleX: config.videoTimelineScale.stepScaleX,
            playerWidth: 1500,
            autoScroll: false,
            autoScrollEnabled: true,
            scaleMarginRight: 0,
            totalSeconds: null,
            widthSegmentItemHeader: null,
            leftOffset: null,
            windowWidth: null,
            clickPositionX: null,
        };
    },
    computed: {
        ...mapState('video', ['video', 'player',]),
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),
    },
    watch: {
        /* Moving currentTimeVertLine based on video current time */
        videoPlayerCurrentTime() {
            this.syncCursorWithCurrentVideoTime();
        },
        widthSegmentItemHeader() {
            this.syncCursorWithCurrentVideoTime();
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    updated() {
        this.totalSeconds = this.video?.duration?.totalSeconds

        if (this.$refs.segment?.[0]) {
            this.widthSegmentItemHeader = this.$refs.segment[0].clientWidth
            this.windowWidth = window.innerWidth
        }
    },
    mounted() {
        this.totalSeconds = this.video?.duration?.totalSeconds

        /* Hide timeline scroll if video playing or show if video stopped */
        /* and fix events width (fix: border-bottom) */
        setInterval((scope) => {
            if (!scope.$refs.timeline) {
                return;
            }

            if (this.player) {
                //scope.$refs.timeline.style.overflowX = 'hidden';
                scope.$refs.timeline.style.overflowX = 'scroll';
            } else {
                scope.$refs.timeline.style.overflowX = 'scroll';
            }

            // Fix border-bottom
            if (!scope.$refs.events) {
                return;
            }

            scope.$refs.events.style.width = `${scope.$refs.timelineHeader.scrollWidth}px`;
        }, 500, this);

        /* User scrolling when video stopped */
        if (this.$refs.timeline) {
            this.$refs.timeline.addEventListener('scroll', function (e) {
                this.handeTimelineScroll(e.target);
                if (this.player) {
                    return;
                }
                this.syncCursorWithScroll(e.target);
            }.bind(this));
        }

        if (this.$refs.persons) {
            this.$refs.persons.addEventListener('scroll', function (e) {
                this.syncPersonsScrolls(e.target);
            }.bind(this));
        }
        /*setTimeout(() => {
            const item = this.video.events.filter(event => event?.text?.includes('Здравствуйте'));
            console.log(item);
        }, 2000);*/

    },

    methods: {
        ...mapActions('video', ['playerOn', 'playerOff']),

        editPerson(person) {
            this.$emit('editPersonCallback', person);
        },

        getParticipantEvents(events: IVideoEventDto[], participant: ParticipantDto) {
            return events.filter(event => {
                return event.participant?.id === participant.id || event.participantId === participant.id;
            });
        },

        handleTimelineClick(timeSec, e) {
            this.clickPositionX = e.layerX

            if (this.$refs.persons && this.$refs.timeline) {
                const offsetLeft = e.clientX - this.$refs.persons.offsetWidth + this.$refs.timeline.scrollLeft;
                const scaleX = parseFloat(this.scaleX);
                const playerW = (this.totalSeconds || 0) * this.oneSecondToPixels * scaleX;
                const k = offsetLeft / playerW;
                const newTime = k * (this.totalSeconds || 0);

                if (this.scaleX  > 0.1) {
                    this.$emit('switchToVideoTime', newTime);
                } else {
                    this.leftOffset = this.clickPositionX
                    this.autoScroll = false;
                    let stepPixelsOnOneSecond = this.widthSegmentItemHeader / this.getStepSeconds()
                    let newTime = this.clickPositionX / stepPixelsOnOneSecond
                    this.$emit('switchToVideoTime', newTime);
                }
            }
        },

        handleResize() {
            const w = window.innerWidth && document.documentElement?.clientWidth ?
                Math.min(window.innerWidth, document.documentElement.clientWidth) :
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.getElementsByTagName('body')[0].clientWidth;
            if (w < 991) {
                this.playerWidth = w - 220;
                this.scaleMarginRight = '50px';
            } else {
                this.playerWidth = w - 285;
                this.scaleMarginRight = '14px';
            }
        },

        /* Wrapper for emit change video playback in parent component */
        playerOnWrapper() {
            this.playerOn();
            this.$emit('changeVideoPlayerCurrentTimeAfterScrollCallback', this.videoPlayerCurrentTimeScroll);
        },

        /* Interval length in seconds to divide the video duration */
        getStepSeconds() {
            if (this.scaleX > 3.5) {
                return 2;
            } else if (2 < this.scaleX && this.scaleX <= 3.5) {
                return 5;
            } else if (0.4 < this.scaleX && this.scaleX <= 2) {
                return 10;
            } else if (0.19999999999999998 < this.scaleX && this.scaleX <= 0.4) {
                return 60;
            } else if (this.scaleX < 0.2) {
                if (this.totalSeconds > 960 && this.totalSeconds < 1800) { // 16-30 min.
                    if (this.windowWidth < 1898) {
                        return 300
                    } else {
                        return 120
                    }
                } else if (this.totalSeconds > 1800 && this.totalSeconds < 3600) { // 30-60 min.
                    if (this.windowWidth < 1810) {
                        return 600
                    } else {
                        return 300
                    }
                } else if (this.totalSeconds > 3600 && this.totalSeconds < 5400) { // 60-90 min.
                    if (this.windowWidth < 1550) {
                        return 900
                    } else {
                        return 600
                    }
                } else if (this.totalSeconds > 5400 && this.totalSeconds < 10800) { // 01:30-03:00 hours
                    if (this.windowWidth < 1463) {
                        return 1200
                    } else {
                        return 900
                    }
                } else if (this.totalSeconds > 10800 && this.totalSeconds < 14400) { // 03:00-04:00 hours
                    if (this.windowWidth < 1897) {
                        return 1800
                    } else {
                        return 1200
                    }
                } else if (this.totalSeconds > 14400 && this.totalSeconds < 18000) { // 04:00-05:00 hours
                    if (this.windowWidth < 1897) {
                        return 2400
                    } else {
                        return 1500
                    }
                } else if (this.totalSeconds > 18000) { // from 05:00 hours and up
                    if (this.windowWidth < 2159) {
                        return 3600
                    } else {
                        return 1500
                    }
                }
            }
            return 120;
        },

        /* Partitions (segments) of an timeline header  */
        getTimelineHeaderSegmentsCount() {
            return Math.floor((this.totalSeconds || 1) / this.getStepSeconds() + 1);
        },

        /* Timeline header segment width  */
        getTimelineHeaderSegmentWidth() {
            const scaleX = parseFloat(this.scaleX);
            return this.getStepSeconds() * this.oneSecondToPixels * scaleX;
        },

        /* Timeline body segment width  */
        getTimelineBodySegmentWidth(startTime: string, endTime: string) {
            const scaleX = parseFloat(this.scaleX);
            const startSeconds = parseTimeString(startTime).totalSeconds;
            const endSeconds = parseTimeString(endTime).totalSeconds;
            if (this.scaleX == 0.1 || this.scaleX > 3.5) {
                return (endSeconds - startSeconds) * (this.widthSegmentItemHeader / this.getStepSeconds())
            } else {
                return (endSeconds - startSeconds) * this.oneSecondToPixels * scaleX;
            }
        },

        /* Timeline body segment margin left  */
        getTimelineBodySegmentMarginLeft(startTime: string) {
            const scaleX = parseFloat(this.scaleX);
            const startSeconds = parseTimeString(startTime).totalSeconds;
            if (this.scaleX < 0.2 || this.scaleX > 3.5) {
                return startSeconds * (this.widthSegmentItemHeader / this.getStepSeconds())
            } else {
                return startSeconds * this.oneSecondToPixels * scaleX;
            }
        },

        /* Get formatted time */
        getTime(value) {
            return getTime(value);
        },

        /* Sync cursor with timeline scroll (actual for timeline scrolling) */
        syncCursorWithScroll(/*scrollElem*/) {
            /*
            let leftOffset = null;
            if (scrollElem.scrollLeft < 1) {
              leftOffset = 0;
            } else if (scrollElem.scrollLeft < (scrollElem.offsetWidth / 2)) {
              // Border left (start of timeline)
              leftOffset = scrollElem.scrollLeft * 2;
            } else if (scrollElem.scrollLeft > (scrollElem.scrollWidth - scrollElem.offsetWidth - scrollElem.offsetWidth / 2)) {
              // Border right (end of timeline)
              const rightOffset = scrollElem.scrollWidth - scrollElem.scrollLeft - scrollElem.offsetWidth;
              leftOffset = scrollElem.scrollLeft + scrollElem.offsetWidth / 2 + (scrollElem.offsetWidth / 2 - rightOffset);
            } else {
              leftOffset = scrollElem.scrollLeft + scrollElem.offsetWidth / 2;
            }

            const maxLeftOffset = this.getTimelineHeaderSegmentWidth() * this.getTimelineHeaderSegmentsCount();
            if (leftOffset > maxLeftOffset) {
              leftOffset = maxLeftOffset;
            }

            this.$refs.currentTimeVertLine.style.left = `${leftOffset}px`;

            this.videoPlayerCurrentTimeScroll = leftOffset * this.videoPlayerDuration / scrollElem.scrollWidth;
            */
        },

        /* Sync cursor with current video time */
        syncCursorWithCurrentVideoTime() {

            if (!this.$refs.timeline || !this.$refs.currentTimeVertLine) {
                return;
            }

            const scaleX = parseFloat(this.scaleX);
            this.videoPlayerCurrentTimeScroll = this.videoPlayerCurrentTime;

            if (this.autoScrollEnabled && this.scaleX > 0.1) {
                this.leftOffset = this.videoPlayerCurrentTime * this.oneSecondToPixels * scaleX;
                if (this.leftOffset > this.$refs.timeline.scrollLeft + this.$refs.timeline.offsetWidth / 2) {
                    const timelineWidthScroll = this.leftOffset - this.$refs.timeline.offsetWidth / 2;
                    this.autoScroll = true;
                    this.$refs.timeline.scrollTo({
                        left: timelineWidthScroll,
                    });
                } else if (this.leftOffset < this.$refs.timeline.scrollLeft) {
                    const timelineWidthScroll = this.leftOffset;
                    this.autoScroll = true;
                    this.$refs.timeline.scrollTo({
                        left: timelineWidthScroll,
                    });
                }
            } else {
                this.autoScroll = false;
                let stepPixelsOnOneSecond = this.widthSegmentItemHeader / this.getStepSeconds()
                this.leftOffset = this.videoPlayerCurrentTime * stepPixelsOnOneSecond
                let time = this.clickPositionX / stepPixelsOnOneSecond
                this.$emit('changeCurrentTimeOfTimeline', time)
            }
        },

        handeTimelineScroll() {
            if (this.autoScroll) {
                this.autoScroll = false;
            } else {
                if (this.player) {
                    this.runAutoScrollPause();
                }
            }
        },

        runAutoScrollPause() {
            this.autoScrollEnabled = false;
            const self = this;
            setTimeout(() => {
                self.autoScrollEnabled = true;
            }, 30000)
        },

        syncPersonsScrolls(scrollElem) {
            this.$refs.events.scrollTop = scrollElem.scrollTop
        },

        /* Timeline scale */
        zoomIn() {
            let s = parseFloat(this.scaleX) + this.stepScaleX;
            if (s > this.maxScaleX) {
                s = this.maxScaleX;
            }
            this.scaleX = s;

            this.syncCursorWithCurrentVideoTime();
        },
        zoomOut() {
            let s = parseFloat(this.scaleX) - this.stepScaleX;
            if (s < this.minScaleX) {
                s = this.minScaleX;
            }
            this.scaleX = s;

            this.syncCursorWithCurrentVideoTime();
        },

        getPersonDisplayLabel(person) {
            return getPersonDisplayLabel(person);
        },
    },
});
