
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import {config} from '@app/config';
import LanguageChanger from '@app/views/blocks/LanguageChanger.vue';
import Sidebar from '@app/views/blocks/Sidebar.vue';
import ErrorLayout from "@app/views/layouts/ErrorLayout.vue";
import NotFoundPage from "@app/views/pages/NotFoundPage.vue";

export default defineComponent({
    name: 'PanelLayout',
    components: {
        ErrorLayout,
        Sidebar,
        LanguageChanger,
        NotFoundPage
    },
    data() {
        return {
            config,
            sidebarOpened: false,
            isNotFoundPage: false,
        }
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isAdmin: 'isAdmin',
        }),
        ...mapGetters('settings', {
            isMlEnabled: 'isMlEnabled'
        }),
        ...mapGetters('brandingSettings', {
            logo: 'logo',
        })
    },

    watch: {
        '$route'(to) {
            this.isNotFoundPage = to.name === 'not-found-page';
        }
    },

    mounted() {
        this.isNotFoundPage = this.$route.name === 'not-found-page';
    },

    methods: {
        async sidebarToggleCallback(opened) {
            this.sidebarOpened = opened;
        },

        closeMobileMenu() {
            document.body.className = document.body.className.replace('mobile-menu--open', '');
        },
    },
});
