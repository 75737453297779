import {axiosVinteoRecordApiWithCredentials} from './index';

/* Get videos by limit, offset */
export async function getVideos(limit, offset, filters) {
    const urlSegment = `videos`;
    const params = {
        filters: filters,
    };
    const response = await axiosVinteoRecordApiWithCredentials.post(urlSegment, {
        'offset': offset,
        'limit': limit,
        params,
    });
    return response.data;
}

/* /Get videos by limit, offset */

/* Video access for user  */
export async function addVideoAccessForUser(videoId, userId) {
    const payload = {
        'user_id': userId,
        'video_id': videoId,
    };
    const urlSegment = `videos/permissions/grant`;
    const response = await axiosVinteoRecordApiWithCredentials.patch(urlSegment, payload);
    return response.data;
}

export async function deleteVideoAccessForUser(videoId, userId) {
    const payload = {
        'user_id': userId,
        'video_id': videoId,
    };
    const urlSegment = `videos/permissions/remove`;
    const response = await axiosVinteoRecordApiWithCredentials.patch(urlSegment, payload);
    return response.data;
}

/* /Video access for user  */

/* Set public or private video  */
export async function changeVideoPublicity(videoId, publicity) {
    const payload = {
        'access': publicity,
    };
    const urlSegment = `videos/${videoId}/access`;
    const response = await axiosVinteoRecordApiWithCredentials.patch(urlSegment, payload);
    return response.data;
}

/* /Set public or private video  */

/* Video Search  */
export async function getSearchResults(query, filters) {
    const payload = {
        'search_word': query,
        'params': {
            'filters': filters,
        },
    };
    const urlSegment = 'videos/search';
    const response = await axiosVinteoRecordApiWithCredentials.post(urlSegment, payload);
    return response.data;
}

/* /Video Search  */

/* Video list by ids  */
export async function getVideosByIds(limit, offset, video_ids) {
    const payload = {
        'video_ids': video_ids,
    };
    const urlSegment = 'videos/list_by_ids';
    const response = await axiosVinteoRecordApiWithCredentials.post(urlSegment, payload);
    return response.data;
}

/* /Video list by ids  */

/* Video description  */
export async function getVideoDescription(video_id) {
    const payload = {
        'video_id': video_id,
    };
    const urlSegment = 'videos/description';
    const response = await axiosVinteoRecordApiWithCredentials.post(urlSegment, payload);
    return response.data;
}

/* /Video description  */

/* Video description  */
export async function saveVideoDescription(video_id, description) {
    const payload = {
        'video_id': video_id,
        'description': description,
    };
    const urlSegment = 'videos/description';
    await axiosVinteoRecordApiWithCredentials.put(urlSegment, payload);
}

/* /Video description  */

/* Video marks  */
export async function changeVideoMark(video_id, mark_field, value) {
    const payload = {
        'video_id': video_id,
        'mark_field': mark_field,
        'value': value,
    };
    const urlSegment = 'videos/marks';
    await axiosVinteoRecordApiWithCredentials.patch(urlSegment, payload);
}

/* /Video marks  */

/* Delete video  */
export async function deleteVideo(video_id) {
    const urlSegment = `videos/${video_id}`;
    await axiosVinteoRecordApiWithCredentials.delete(urlSegment);
}

/* /Delete video  */
