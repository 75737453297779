
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import {
    wsApplyNoteChanges,
    wsCbEndedType,
    wsCbJoinedNoteEditing,
    wsCbNoteChanged,
    wsCbStartedType,
    wsCloseNoteEditing,
    wsJoinNoteEditing,
    wsNoteTypingEnded,
    wsNoteTypingStarted,
} from '@app/system/api/old/videoNotes';


export default defineComponent({
    name: 'Note',
    props: {
        /*
          Expecting an object with fields:
          - id: <int>
          - note: <str>
          - conference_id: <str or int>
          - end_time: <str or int>
          - start_time: <str or int>
        */
        noteData: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            visible: false,
            textareaValue: null,
            textareaValueDubl: null,
            usersTyping: [],
        };
    },
    computed: {
        ...mapGetters('auth', ['jwt']),

        noteText() {
            if (!this.noteData || !this.noteData.note) {
                return null;
            }
            return this.noteData.note;
        },

        noteId() {
            if (!this.noteData || !this.noteData.id) {
                return null;
            }
            return this.noteData.id;
        },
    },
    mounted() {
        // Initial note text from  server
        this.textareaValue = this.noteText;

        // Create WebSocket connection
        if (!this.noteId) {
            return;
        }
        try {
            wsJoinNoteEditing(this.jwt(), this.noteId);

            // Start callback events
            wsCbJoinedNoteEditing(this.wsJoinedCallback);
            wsCbNoteChanged(this.wsChangedCallback);
            wsCbStartedType(this.wsStartTypeCallback);
            wsCbEndedType(this.wsEndTypeCallback);
        } catch (e) {
            console.log(e);
        }
    },
    beforeUnmount() {
        try {
            wsCloseNoteEditing();

        } catch (e) {
            console.log(e);
        }
    },
    methods: {
        toggleVisibility() {
            this.visible = !this.visible;
        },
        sendNoteChangesByKeyDown() {
            this.textareaValueDubl = this.textareaValue;
        },
        sendNoteChangesByKeyUp(e) {
            if (!this.noteId || e.keyCode === 8 || this.textareaValue.length === this.textareaValueDubl.length) {
                return;
            }

            wsApplyNoteChanges(this.jwt(), this.noteId, this.textareaValue, 0, this.textareaValue.length);
        },

        sendNoteChangesByBackspace(e) {
            if (!this.noteId) {
                return;
            }

            let selectionStart = e.target.selectionStart;
            let selectionEnd = e.target.selectionEnd;

            // For partail editing
            if (e.target.selectionStart === e.target.selectionEnd) {
                selectionStart = selectionStart - 1;
                if (selectionStart < 0) {
                    selectionStart = 0;
                    selectionEnd = 1;
                }
            }

            const len = selectionEnd - selectionStart;
            wsApplyNoteChanges(this.jwt(), this.noteId, '', selectionStart, len);
        },

        typingStarted() {
            if (!this.noteId) {
                return;
            }
            wsNoteTypingStarted(this.jwt(), this.noteId)
        },

        typingEnded() {
            if (!this.noteId) {
                return;
            }
            wsNoteTypingEnded(this.jwt(), this.noteId)
        },

        /* Callback's for WebSocket */
        // User joined for edit note text
        wsJoinedCallback() {
        },
        // Changed note text
        wsChangedCallback(respData) {
            if (typeof respData.changes !== 'string') {
                return;
            }

            // Set note text from server
            const startPosition = respData.startPosition;
            const replaceLength = respData.replaceLength;
            if (respData.changes.length > 0) {
                // Adding text
                this.textareaValue = respData.changes;
            } else {
                // Delete text
                this.textareaValue = this.textareaValue.substring(0, startPosition) + this.textareaValue.substring(startPosition + replaceLength);
            }
        },
        // Started typing note text by user
        wsStartTypeCallback(respData) {
            // Add to usersTyping array
            let index = this.usersTyping.findIndex(user => user === respData.userId);
            if (index < 0) {
                this.usersTyping.push(respData.userId);
            }
        },
        // Ended typing note text by user
        wsEndTypeCallback(respData) {
            // Delete from usersTyping array
            let index = this.usersTyping.findIndex(user => user === respData.userId);
            if (index > -1) {
                this.usersTyping.splice(index, 1)
            }
        },
        /* /Callback's for WebSocket */
    },
});
