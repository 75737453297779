
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import {config} from '@app/config';
import EditPersonModal from '@app/modules/person/blocks/EditPersonModal.vue';
import Accesses from '@app/modules/video/blocks/Accesses.vue';
import Protocol from '@app/modules/video/blocks/Protocol.vue';
import {getPersonDisplayLabel} from '@app/utils/helpers';

const avatarPlaceholderImg = require('@app/assets/icons/avatar_placeholder.png');

const __default__ = defineComponent({
    name: 'ProtocolBlock',
    components: {
        EditPersonModal,
        Accesses,
        Protocol,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
        video: {
            type: Object,
            required: true,
        },
        videoPlayerCurrentTime: {
            type: Number,
            default: 0,
        },
        videoPlayerDuration: {
            type: Number,
            default: 0,
        },
        protocolHeight: {
            type: Number,
            default: null,
        },
        windowWidth: {
            type: Boolean,
            default: null,
        },
        skeletonProtocolHeightOne: {
            type: Boolean,
            default: null,
        },
        skeletonProtocolHeightTwo: {
            type: Boolean,
            default: null,
        },
        editPersonCallback: {
            type: Function,
            required: true,
        },
        selectTab: {
            type: Function,
            required: true,
        },
        tabIsSelected: {
            type: Function,
            required: true,
        },
        videoSectionHeight: {
            type: Boolean,
            default: null,
        },
        getVideoHeight: {
            type: Function,
            required: true,
        },
    },
    emits: ['handleProtocolTimeClick'],
    data() {
        return {
            recognitionMode: config.recognitionMode,
            avatarPlaceholderImg,
            heightListAccessMobile: 350,
            heightListAccessDesktop: null,
            participantSearchStr: '',

            featureAccess: false,
        };
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isGrantedPolicyForSome: 'isGrantedPolicyForSome',
            jwt: 'jwt',
        }),
        ...mapGetters('settings', {
            isMlEnabled: 'isMlEnabled'
        }),
        accessToken() {
            return this.jwt();
        }
    },
    updated() {
        this.heightListAccessDesktop = (this.videoSectionHeight + 215) + 'px';
    },
    methods: {
        getPersonDisplayLabel(person) {
            return getPersonDisplayLabel(person);
        },
        handleProtocolTimeClick(time) {
            this.$emit('handleProtocolTimeClick', time);
        },

        editAccesses() {
            this.$refs.editPersonModal.show();
        },

        removeAccesses() {
            this.$refs.modalDelete.show();
        },

        getVideoPersons() {
            const query = this.participantSearchStr.trim();
            try {
                if (query.length > 0) {
                    const re = new RegExp(query, 'i');
                    return this.video.participants.filter(participant => getPersonDisplayLabel(participant)?.match(re));
                } else {
                    return this.video.participants;
                }
            } catch (e) {
                return [];
            }
        },
    },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "c1d633b0": (_ctx.heightListAccessDesktop)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__