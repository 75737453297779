import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "title"]
const _hoisted_2 = ["id", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIconLink = _resolveComponent("BIconLink")!
  const _directive_b_tooltip = _resolveDirective("b-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.publicAccess === 'allowed_users')
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          id: 'tooltip-copy-inner-link-' + _ctx.videoId,
          class: "copy-link-btn",
          title: _ctx.$t('page_titles.copy_video_inner_link'),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clipboardCopyText && _ctx.clipboardCopyText(...args)), ["stop"]))
        }, [
          _createVNode(_component_BIconLink)
        ], 8, _hoisted_1)), [
          [_directive_b_tooltip]
        ])
      : _createCommentVNode("", true),
    (_ctx.publicAccess !== 'allowed_users')
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 1,
          id: 'tooltip-copy-public-link-' + _ctx.videoId,
          class: _normalizeClass(_ctx.accessComponent ? 'copy-link-btn-public__access' : 'copy-link-btn-public'),
          title: _ctx.$t('page_titles.copy_video_public_link'),
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clipboardCopyText && _ctx.clipboardCopyText(...args)), ["stop"]))
        }, null, 10, _hoisted_2)), [
          [_directive_b_tooltip]
        ])
      : _createCommentVNode("", true)
  ]))
}