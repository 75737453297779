

import {getPersonDisplayLabel} from "@app/utils/helpers";

import {defineComponent} from 'vue';

import {config} from '@app/config';
import {mapGetters} from "vuex";

const avatarPlaceholderImg = require('@app/assets/icons/avatar_placeholder.png');

export default defineComponent({
    name: 'VideoUsersInVideoInstance',
    props: {
        video: {
            type: Object,
            default: null
        },
    },
    emits: ['editPersonCallback'],
    data() {
        return {
            config,
            photoPath: config.photoPath,
            dropdownVisible: false,
            avatarPlaceholderImg,
        };
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),
    },
    mounted() {

    },
    methods: {
        editPerson(person) {
            this.$emit('editPersonCallback', person);
            this.dropdownVisible = !this.dropdownVisible;
        },

        slicePersons(video) {
            return video?.participants?.slice(0, 2) || [];
        },

        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },

        getPersonDisplayLabel(person) {
            return getPersonDisplayLabel(person);
        },
    }
});
