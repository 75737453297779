
import {defineComponent} from 'vue';

import {setI18nLocale} from '@app/i18n';

export default defineComponent({
    name: 'LanguageChanger',
    methods: {
        changeLocale(locale) {
            setI18nLocale(locale);
        },
    },
});
