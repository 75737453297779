import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    ref: "modal",
    "modal-show": _ctx.modalShow,
    title: _ctx.$t('modal_titles.ended_session_message'),
    size: "lg",
    "with-cancel": false,
    "ok-title": _ctx.$t('buttons_common.exit'),
    "ok-variant": "secondary",
    onHidden: _ctx.handleClose,
    onOk: _ctx.handleClose
  }, null, 8, ["modal-show", "title", "ok-title", "onHidden", "onOk"]))
}