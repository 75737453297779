import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "togglebox" }
const _hoisted_2 = { class: "knobs" }
const _hoisted_3 = { class: "layer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      type: "checkbox"
    }, null, 512), [
      [_vModelCheckbox, _ctx.value]
    ]),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "items")
    ]),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "items")
    ])
  ]))
}