import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';

import {getIdsrvAuth} from '@app/modules/auth/services/idsrv-auth';
import {config} from '@app/config';
import PanelTemplate from '@app/views/layouts/PanelLayout.vue';
import PanelVideoTemplate from '@app/views/layouts/PanelVideoLayout.vue';
import {isAdmin, isGrantedPolicy} from '@app/modules/auth/auth.store';
import VideosInstancePage from '@app/modules/video/pages/VideosInstancePage.vue';
import LinkPage from '@app/modules/video/pages/LinkPage.vue';

export function getRouter() {
    const idsrvAuth = getIdsrvAuth();

    const sidebarRoutes: RouteRecordRaw[] = [
        {
            path: 'videos',
            name: 'videos',
            props: true,
            component: async () => await import('../modules/video/pages/VideosPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
                // policy: 'Recorder.Video',
            },
        },
        {
            path: 'roles',
            name: 'roles',
            component: async () => await import('../modules/user/pages/RolesPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
                policy: 'AccessKit.AccessGroup',
            },
        },
        {
            path: 'users',
            name: 'users',
            component: async () => await import('../modules/user/pages/UsersPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
                policy: 'Recorder.UserAccount',
            },
        },
        /*{
            path: 'broadcast',
            name: 'broadcast',
            component: async () => await import('../views/panel/Broadcast.vue'),
            meta: {
                authName: idsrvAuth.authName,
            },
        },
        {
            path: 'journal',
            name: 'journal',
            component: async () => await import('../views/panel/Journal.vue'),
            meta: {
                authName: idsrvAuth.authName,
            },
        },*/
        {
            path: 'system',
            name: 'system',
            component: async () => await import('../modules/system/pages/SystemPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
                // policy: 'Recorder.UserAccount',
            },
        },
        {
            path: 'branding-settings',
            name: 'branding-settings',
            component: async () => await import('../modules/settings/pages/BrandingSettingsPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
                // policy: 'Recorder.UserAccount',
            },
        },
        {
            path: 'NotificationsPreferences',
            name: 'notifications-preferences',
            component: async () => await import('../modules/settings/pages/NotificationsPreferencesPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
                // policy: 'Recorder.UserAccount',
            },
        },
        {
            path: 'audit',
            name: 'audit',
            component: async () => await import('../modules/audit/pages/AuditPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
                // policy: 'Recorder.UserAccount',
            },
        },
        {
            path: 'licenses',
            name: 'licenses',
            component: async () => await import('../modules/license/pages/LicensesPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
                // policy: 'Recorder.UserAccount',
            },
        },
        {
            path: '/402',
            name: '402',
            component: async () => await import('../views/pages/LicenseRequiredPage.vue'),
        },
        {
            path: '/403',
            name: '403',
            component: async () => await import('../views/pages/AccessDeniedPage.vue'),
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found-page',
            component: async () => await import('../views/pages/NotFoundPage.vue'),
        },
    ];

    if (config.recognitionMode) {
        sidebarRoutes.push({
            path: 'persons',
            name: 'persons',
            component: async () => await import('../modules/person/pages/RecognizedPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
            },
        });
    } else {
        sidebarRoutes.push({
            path: 'persons',
            name: 'persons',
            component: async () => await import('../modules/person/pages/InstructionPage.vue'),
            meta: {
                authName: idsrvAuth.authName,
            },
        });
    }

    sidebarRoutes.push({
        path: 'unrecognized_persons',
        name: 'unrecognized_persons',
        component: async () => await import('../modules/person/pages/UnrecognizedPage.vue'),
        meta: {
            authName: idsrvAuth.authName,
        },
    });

    const routes: RouteRecordRaw[] = [
        {
            path: '/',
            name: 'panel',
            redirect: '/videos',
            component: PanelTemplate,
            meta: {
                authName: idsrvAuth.authName,
            },
            children: sidebarRoutes,
        },
        {
            path: '/',
            component: PanelVideoTemplate,
            meta: {
                authName: idsrvAuth.authName,
            },
            children: [
                {
                    path: 'videos/:id',
                    name: 'videos-instance',
                    props: true,
                    component: VideosInstancePage,
                },
            ],
        },
        {
            path: '/links/:accessTicketId',
            name: 'share',
            props: true,
            component: LinkPage,
        },

        /*{
            path: '*',
            component: async () => await import('../views/NotFound.vue'),
        },*/
    ];

    const router = createRouter({
        history: createWebHistory('/'),
        routes,
    });

    idsrvAuth.useRouter(router);

    router.beforeEach(async (to, from) => {
        if (to.meta?.policy && !isGrantedPolicy(to.meta.policy as string)) {
            return {name: '403'};
        }

        if (to.name === 'system' && !isAdmin()) {
            return {name: '403'};
        }
    });

    return router;
}
