
import Datepicker from '@vuepic/vue-datepicker';
import {defineComponent} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

import {parseTimeString} from '@app/utils/helpers';
import '@vuepic/vue-datepicker/dist/main.css';
import {api} from '@app/system/api/api-services';
import {IVideoEventDto} from '@app/system/api/service-proxies/service-proxies';
import {i18n} from '@app/i18n';

import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment/moment';

export default defineComponent({
    name: 'Protocol',
    components: {
        Datepicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        videoPlayerCurrentTime: {
            type: Number,
            default: 0,
        },
        videoPlayerDuration: {
            type: Number,
            default: 0,
        },
        protocolHeight: {
            type: Number,
            require: true,
        },
    },
    emits: ['switchToVideoTime'],

    data() {
        return {
            searchTextMinLength: 3,
            searchText: '',

            // For searchText controls
            matchesCurrentIndex: 0,
            matchesNumber: 0,
            matches: [],
            height: 550,
            autoScroll: false,
            disableAutoScroll: false,
            protocolEditing: false,
            participantSelected: [],
            participantId: [],
            isEditing: false,
            eventsIds: [],
            fullSecondsEvents: [],
            indexEl: 0,
            delta: 160,
            scrollingProtocol: false,
            windowWidth: null,
            featuresEditingProtocol: true,

            // Time picker
            time: {},
            startTime: {
                hours: 0,
                minutes: 0,
                seconds: 0,
            },
            locale: 'ru',
            videoCopy: null,
        };
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isGrantedPolicyForSome: 'isGrantedPolicyForSome',
            jwt: 'jwt',
        }),
        ...mapState('video', ['video', 'player', 'isParticipantName']),

        isSubmitAvailable() {
            if (Object.keys(this.time).length) {
                return true;
            }
            return this.isEditing;
        },
        isGrantedForCreate() {
            return this.isGrantedPolicyForSome([
                'Recorder.Video.Update',
            ]);
        },
        accessToken() {
            return this.jwt();
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        setTimeout(this.handleResize.bind(this), 0);
    },
    updated() {
        this.windowWidth = window.innerWidth;

        // Скроллинг стенограммы при воспроизведении видео
        this.fullSecondsEvents.forEach((item, index) => {
            if (item != 0 && item === Math.floor(this.videoPlayerCurrentTime)) {
                this.indexEl = index;
            }
        });

        let timeElem = document.querySelector(`.times_index_${this.indexEl}`);

        if (!timeElem) {
            return;
        }

        if (this.player && !this.disableAutoScroll && this.indexEl != 0) {
            this.$refs.protocolContent.scrollTo({
                top: (timeElem as any).offsetTop - this.delta,
                behavior: 'smooth',
            });
        }
    },
    mounted() {
        this.videoCopy = cloneDeep(this.video);

        this.video?.texts?.forEach(item => this.fullSecondsEvents.push(parseTimeString(item.startTime.slice(
            0,
            8,
        )).totalSeconds));

        if (this.$refs.protocolContent) {
            this.$refs.protocolContent.addEventListener('scroll', function() {
                if (!this.autoScroll) {
                    this.disableAutoScroll = true;
                    clearInterval(this.tsDisableAutoScroll);
                    if (this.scrollingProtocol) {
                        this.tsDisableAutoScroll = setTimeout(function() {
                            this.disableAutoScroll = false;
                            this.scrollingProtocol = false;
                        }.bind(this), 30000);
                    } else {
                        this.tsDisableAutoScroll = setTimeout(function() {
                            this.disableAutoScroll = false;
                        }.bind(this), 1000);
                    }

                } else {
                    clearInterval(this.tsAutoScroll);
                    this.tsAutoScroll = setTimeout(function() {
                        this.autoScroll = false;
                    }.bind(this), 0);
                }
            }.bind(this));
        }

        // TODO: Old code scrolling of protocol
        /*// Moving vert scroll via endless cycle
        const delta = 160;
        let startSeconds = 0;
        let endSeconds = 0;
        // С какого элемента начинаем обход
        let currentIndex = 0;
        // Количество элементов в массиве
        let arrLength = this.video.texts?.length ? this.video.texts?.length - 1 : 0;

        setInterval((scope) => {
            if (!this.video.texts?.length || !this.video.texts[currentIndex] || !this.video.texts[currentIndex].startTime ||
                !this.video.texts[currentIndex].endTime) {
                return;
            }

            startSeconds = parseTimeString(this.video.texts[currentIndex].startTime).totalSeconds;
            endSeconds = parseTimeString(this.video.texts[currentIndex].endTime).totalSeconds;

            if (startSeconds <= scope.videoPlayerCurrentTime && scope.videoPlayerCurrentTime <= endSeconds) {
                // TODO: delete after test
                // console.log('scope.videoPlayerCurrentTime', scope.videoPlayerCurrentTime);
                // console.log('endTime', endTime);

                // Get time elem
                let timeElem = document.querySelector(`.times_index_${currentIndex}`);

                if (!timeElem) {
                    return;
                }

                // TODO: delete after test
                // console.log('timeElem', timeElem);
                // console.log('timeElem.offsetTop', timeElem.offsetTop);
                // console.log('timeElem.scrollHeight - delta = ', timeElem.offsetTop - delta);

                // Scroll to
                if (this.player && !this.disableAutoScroll && currentIndex != 0) {
                    this.autoScroll = true;
                    scope.$refs.protocolContent.scrollTo({
                        top: (timeElem as any).offsetTop - delta,
                        // behavior: 'smooth',
                    });
                }
            }

            // Next index...
            if (currentIndex >= arrLength) {
                currentIndex = 0;
            } else {
                currentIndex++;
            }

        }, 0, this);*/

        this.locale = i18n.global.locale;
    },

    methods: {
        ...mapActions('video', {
            getVideo: 'getVideo',
        }),

        ...mapMutations('video', {
            setVideo: 'setVideo',
        }),

        handlePicker(time) {
            this.startTime.hours = time.split(':')[0];
            this.startTime.minutes = time.split(':')[1];
            this.startTime.seconds = Math.floor(time.split(':')[2]);

            if (this.locale === 'ru') {
                const btnCancel = document.querySelector('.dp__action_cancel');
                const btnSelect = document.querySelector('.dp__action_select');
                btnCancel.textContent = 'Отмена';
                btnSelect.textContent = 'Выбрать';
            }
        },

        handleScrollingProtocol() {
            this.scrollingProtocol = true;
        },

        handleResize() {
            const h = window.innerHeight || document.documentElement.clientHeight;
            const w = window.innerWidth && document.documentElement.clientWidth ?
                Math.min(window.innerWidth, document.documentElement.clientWidth) :
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.getElementsByTagName('body')[0].clientWidth;

            const videoEl = document.getElementById('videoPlayer');
            if (videoEl) {
                const videoElOffsetH = videoEl.offsetHeight;

                if (w < 991) {
                    this.height = h - videoElOffsetH - 311;
                    // this.$emit('height', this.height)
                } else if (w > 991 && w <= 1365) {
                    this.height = videoElOffsetH - 60;
                    // this.$emit('height', this.height)
                } else {
                    this.height = videoElOffsetH + 237;
                }
            }

        },

        // Get formatted time
        getTime(value: string) {
            return value.slice(0, 8);
        },

        // Only await mode
        highlighter(author, string, searchText, index) {
            let result = String(string);
            this.matches.forEach((m, i) => {
                if (m.index !== index) {
                    return;
                }

                const re = new RegExp(searchText.replace(/\W|_/g, '[$&]'), 'gi');

                let spanClass = 'text-highlighter';
                if (i + 1 === this.matchesCurrentIndex) {
                    spanClass = 'text-highlighter_darker';
                }

                result = result.replace(re, `<span class="${spanClass}">${'$&'}</span>`);
            });
            if (author !== '') {
                result = `<span style="color:gray"><${author}></span> ` + result;
            }
            return result;
        },

        // Reset search text
        resetSearchText() {
            this.matchesCurrentIndex = 0;
            this.matchesNumber = 0;
            this.matches = [];
            this.searchText = '';
        },

        nextMatchesCurrentIndex() {
            if (this.matches?.length < 1) {
                return;
            }

            let t = this.matchesCurrentIndex;
            t += 1;
            if (t > this.matchesNumber) {
                this.matchesCurrentIndex = 1;
            } else {
                this.matchesCurrentIndex = t;
            }

            // Scroll
            const refIndex = this.matches[this.matchesCurrentIndex - 1].index;
            const elem = this.$refs[`text_${refIndex}`];
            this.$refs.protocolContent.scrollTo({
                top: elem[0].offsetTop - 185,
                behavior: 'smooth',
            });
        },

        prevMatchesCurrentIndex() {
            if (this.matches?.length < 1) {
                return;
            }

            let t = this.matchesCurrentIndex;
            t -= 1;
            if (t <= 0) {
                this.matchesCurrentIndex = this.matches?.length;
            } else {
                this.matchesCurrentIndex = t;
            }

            // Scroll
            const refIndex = this.matches[this.matchesCurrentIndex - 1].index;
            const elem = this.$refs[`text_${refIndex}`];
            this.$refs.protocolContent.scrollTo({
                top: elem[0].offsetTop - 100,
            });
        },

        switchToVideoTime(timeText: string) {
            const {totalSeconds} = parseTimeString(timeText);
            this.$emit('switchToVideoTime', totalSeconds);
        },

        searchTextChange(val) {
            val = this.searchText.replace(/\W|_/g, '[$&]');

            this.matchesCurrentIndex = 0;
            this.matchesNumber = 0;
            this.matches = [];

            //  Match searching. If found then saved index.
            if (!val || val.length < this.searchTextMinLength + 1) {
                return;
            }

            this.video.texts?.forEach((item, index) => {
                const text = item.text;

                if (text.length < 1) {
                    return;
                }

                const re = new RegExp(val, 'gi');
                const matched = text.match(re);

                if (matched) {
                    this.matchesNumber += 1;
                    // Save index
                    this.matches.push({
                        'index': index,
                        'val': text,
                    });
                    //
                    this.matchesCurrentIndex = 1;
                }
            });
        },

        // Protocol editing
        handleClickEditing() {
            this.protocolEditing = !this.protocolEditing;
        },

        handleClickDownloadProtocol() {
            let textToSave = '';
            const creationTime = moment(this.video.creationTime).format('DD.MM.YYYY');
            const videoDescription = this.video.description;
            this.video.texts.forEach((item) => {
                const author = item.participant?.fullName;
                const startTime = item.startTime.split('.')[0];
                const text = item.text.trim();
                textToSave += `${author ? author : '-'} ${startTime}\n${text}\n`;
            });

            const blob = new Blob([textToSave], {type: 'text/plain'});
            const link = document.createElement('a');
            link.download = `${creationTime}_${videoDescription}.txt`;
            link.href = window.URL.createObjectURL(blob);
            link.click();
        },

        handleCancelEditing() {
            this.protocolEditing = !this.protocolEditing;
            this.getVideo({id: this.id});
            this.time = {};
            this.participantSelected = [];

            this.setVideo(this.videoCopy);
        },

        handleEditingText(e, index) {
            this.video.texts.map((txt, i) => {
                if (index === i) {
                    txt.text = e.target.innerText;
                    txt.editing = true;
                    this.isEditing = true;
                }
            });
            if (!e.target.innerText.length) {
                this.isEditing = false;
            }

            let texts = e.target.innerText.split(' ');
            this.matches = texts.map(t => t.toLocaleUpperCase());
        },

        handleRemoveRecording(item, i) {
            this.video.texts.splice(i, 1);
            this.participantSelected.splice(i, 1);
            for (let time in this.time) {
                if (i === +time) {
                    delete this.time[time];
                }
            }
            this.isEditing = true;
            this.eventsIds.push(item.id);
        },

        handleChangeUserName(index) {
            this.video.participants.forEach((item) => {
                if (item.fullName === this.participantSelected[index]) {
                    this.participantId[index] = item.id;
                }
            });

            this.video.texts.map((item, idx) => {
                if (index === idx) {
                    item.participantId = this.participantId[index];
                    item.editing = true;
                    this.isEditing = true;
                }
            });
        },

        async handleClickSave() {
            const times = [];
            const editedEvents = [];

            for (let time in this.time) {
                const hours = this.time[time].hours.toString();
                const minutes = this.time[time].minutes.toString();
                const seconds = this.time[time].seconds.toString();

                if (hours.length < 2 && minutes.length < 2 && seconds.length === 2) {
                    times[time] = '0' + hours + ':' + '0' + minutes + ':' + seconds;
                }
                if (hours.length < 2 && minutes.length < 2 && seconds.length < 2) {
                    times[time] = '0' + hours + ':' + '0' + minutes + ':' + '0' + seconds;
                }
                if (hours.length === 2 && minutes.length < 2 && seconds.length < 2) {
                    times[time] = hours + ':' + '0' + minutes + ':' + '0' + seconds;
                }
                if (hours.length === 2 && minutes.length < 2 && seconds.length === 2) {
                    times[time] = hours + ':' + '0' + minutes + ':' + seconds;
                }
                if (hours.length === 2 && minutes.length === 2 && seconds.length < 2) {
                    times[time] = hours + ':' + minutes + ':' + '0' + seconds;
                }
                if (hours.length < 2 && minutes.length === 2 && seconds.length === 2) {
                    times[time] = '0' + hours + ':' + minutes + ':' + seconds;
                }
                if (hours.length < 2 && minutes.length === 2 && seconds.length < 2) {
                    times[time] = '0' + hours + ':' + minutes + ':' + '0' + seconds;
                }
            }

            times.forEach((time, i) => {
                this.video.texts.forEach((item, idx) => {
                    if (i === idx) {
                        item.startTime = time;
                        item.editing = true;
                    }
                });
            });

            this.video.texts.forEach(item => {
                if (item.editing) {
                    editedEvents.push(item);
                }
            });
            editedEvents.forEach(item => {
                const protocolDto: Partial<IVideoEventDto> = {
                    id: item.id,
                    videoId: item.videoId,
                    participantId: item.participantId,
                    text: item.text,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    eventType: item.eventType,
                    emotionType: item.emotionType,
                } as any;

                api.videoEventServiceProxy.save('1.0', protocolDto as any).toPromise();
            });

            this.eventsIds.forEach(item => api.videoEventServiceProxy.delete(item as any, '1.0').toPromise());

            this.protocolEditing = false;
            this.participantSelected = [];
            this.time = {};
        },
    },
});
