import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fe4254e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "b_filter_items" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    id: "select-filter-items-modal",
    ref: "modal",
    "modal-show": _ctx.modalShow,
    title: _ctx.$t('modal_titles.select_filter'),
    persistent: true,
    "with-footer": false,
    onShow: _ctx.resetModal,
    onHidden: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterItems, (filterItem) => {
          return (_openBlock(), _createElementBlock("div", {
            key: filterItem.id,
            class: "mt-2"
          }, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["btn btn-small mr-2", {
                        'btn-gray': _ctx.activeFilterItem?.id !== filterItem.id,
                        'btn-blue': _ctx.activeFilterItem?.id === filterItem.id,
                    }]),
              "data-dismiss": "modal",
              onClick: ($event: any) => (_ctx.onSelectFilterItem(filterItem))
            }, _toDisplayString(filterItem.title), 11, _hoisted_2)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["modal-show", "title", "onShow", "onHidden"]))
}