import moment from 'moment/moment';

export function getJwtPayload() {
    const $oidc = window['$oidc'];

    const jwt = $oidc.accessToken || null;

    if (!jwt) {
        return null;
    }

    const token = jwt;

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return jsonPayload;

}

export function checkJwt() {
    const $oidc = window['$oidc'];

    const jwt = $oidc.accessToken || null;

    if (!jwt) {
        return false;
    }

    // Check by exist key 'exp'

    const json = getJwtPayload();

    const jwtPayload = JSON.parse(json);

    if (!jwtPayload.exp) {
        localStorage.removeItem('jwt');
        return false;
    }

    // Check by expired
    const expired = moment(jwtPayload.sub.exp).unix();
    const now = moment().unix();
    if (now > expired) {
        localStorage.removeItem('jwt');
        return false;
    }

    return true;
}
