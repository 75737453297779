import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!

  return (_openBlock(), _createBlock(_component_b_dropdown, {
    id: "language-changer-desktop",
    text: _ctx.$i18n.locale,
    class: "language-changer text-uppercase"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_dropdown_item, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLocale('ru')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" RU ")
        ]),
        _: 1
      }),
      _createVNode(_component_b_dropdown_item, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLocale('en')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" EN ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["text"]))
}