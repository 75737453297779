import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "error-snackbar__inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: $data.showSnackbar,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.showSnackbar) = $event)),
    "content-class": "error-snackbar",
    color: $data.color,
    timeout: $data.timer
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        density: "compact",
        icon: "mdi-close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onClickClose($event)))
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_icon, { style: {"margin-right":"8px"} }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($data.icon), 1)
          ]),
          _: 1
        }),
        _createElementVNode("span", null, _toDisplayString($data.message), 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "color", "timeout"]))
}