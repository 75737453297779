import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-415eec73"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mt-1 invalid-message"
}
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    id: "edit-description-modal",
    ref: "modal",
    "modal-show": _ctx.modalShow,
    width: "500",
    title: _ctx.$t('modal_titles.video_description'),
    "ok-title": _ctx.$t('buttons.save'),
    "cancel-title": _ctx.$t('buttons.cancel'),
    "ok-disabled": !_ctx.isSubmitAvailable,
    "is-additional-button": _ctx.isAdditionalButton,
    onShow: _ctx.resetModal,
    onOk: _ctx.submitForm,
    onHidden: _ctx.closeModal
  }, {
    additionalButton: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-red",
        "data-dismiss": "modal",
        disabled: _ctx.isDisable,
        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeVideo && _ctx.removeVideo(...args)), ["stop"]))
      }, _toDisplayString(_ctx.$t('delete_video_text')), 9, _hoisted_2)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_form, {
        onSubmit: _ctx.submitForm,
        onReset: _ctx.resetForm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_form_group, {
            label: _ctx.$t('table_titles.name'),
            "label-for": "input-name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_form_input, {
                id: "input-name",
                modelValue: _ctx.v$.form.description.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.form.description.$model) = $event)),
                modelModifiers: { trim: true },
                class: _normalizeClass({
                        'invalid' : _ctx.v$.form.description.$error,
                        'is-valid' : _ctx.v$.form.description.$dirty && !_ctx.v$.form.description.$error
                    })
              }, null, 8, ["modelValue", "class"]),
              (_ctx.v$.form.description.$error && _ctx.videoNameStatus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('modal_titles.minLength')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_b_form_checkbox, {
            id: "checkbox",
            modelValue: _ctx.form.isProtected,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.isProtected) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`checkbox_labels.not_automatically_delete`)), 1)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["onSubmit", "onReset"])
    ]),
    _: 1
  }, 8, ["modal-show", "title", "ok-title", "cancel-title", "ok-disabled", "is-additional-button", "onShow", "onOk", "onHidden"]))
}