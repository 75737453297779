
import 'splitpanes/dist/splitpanes.css';

import {defineComponent} from 'vue';

import VideoInstancePage from '@app/modules/video/pages/VideosInstancePage.vue';
import {api} from '@app/system/api/api-services';

export default defineComponent({
    name: 'LinkPage',
    components: {VideoInstancePage},
    props: {
        accessTicketId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            videoId: '',
        };
    },
    computed: {},
    watch: {},

    created() {

    },

    updated() {

    },

    async mounted() {
        try {
            const {accessTicketId} = this;

            window['$accessTicketId'] = accessTicketId;

            const accessTicket = await api.accessTicketServiceProxy.getInclude(accessTicketId, '1.0', []).toPromise();

            this.videoId = accessTicket.targetUrl.split('/')[2];
        } catch (e) {
            console.error(e);
        }
    },

    methods: {},
});
