
import {useVuelidate} from '@vuelidate/core'
import {minLength, required} from '@vuelidate/validators'
import {defineComponent} from 'vue';
import Modal from "@app/components/ui-kit/molecules/Modal.vue";
import {mapActions} from "vuex";

export default defineComponent({
    name: 'SaveVideoTrim',
    components: {
        Modal,
    },
    props: {
        video: {
            type: Object,
            required: true
        },
        showModal: {
            type: Boolean,
            default: false
        },
        /*newVideo: {
            type: Boolean,
            default: false
        },*/
        result: {
            type: Object,
            required: true,
            default: () => ({
                title: '',
                originVideoId: '',
                fragments: [],
            })
        },
        currentTimeStart: {
            type: Number,
            default: 0
        },
        currentTimeEnd: {
            type: Number,
            default: 0
        },
    },
    emits: ['closeVideoTrimModal'],

    setup() {
        return {v$: useVuelidate()}
    },

    data() {
        return {
            id: 'save-video-trim-modal',
            form: {
                description: '',
                isConfidential: false,
            },
            modalShow: false,
        }
    },
    computed: {
        isDisable() {
            const isDescriptionShort = this.form.description.length < 2;
            const isTitleAndConfidentialSame = this.result.title === this.form.description && this.result.isConfidential === this.form.isConfidential;
            const isTimeWithinBounds = this.currentTimeStart <= 0 && (!this.currentTimeEnd || this.currentTimeEnd > this.video.duration.totalSeconds);

            return isDescriptionShort || (isTitleAndConfidentialSame && isTimeWithinBounds);
        },
    },
    validations() {
        return {
            form: {
                description: {required, minLength: minLength(2)},
            },
        }
    },

    methods: {
        ...mapActions('video', {
            videoTrim: 'videoTrim'
        }),

        show() {
            this.modalShow = true;
            this.v$.$reset()
        },

        hide() {
            this.modalShow = false;
        },

        async submitForm() {
            const resultCopy = {...this.result}
            resultCopy.title = `${this.form.description} (фрагмент)`
            resultCopy.isConfidential = this.form.isConfidential
            this.hide()
            await this.videoTrim(resultCopy)
            await this.$emit('closeVideoTrimModal')
        },

        resetForm() {
            this.form.description = this.video.description
            this.form.isConfidential = this.video.isConfidential
        },

        resetModal() {
            this.resetForm()
        },

        async closeModal() {
            this.hide();
        },
    }
});
