
import {defineComponent} from "vue";

import Player from "@app/modules/video/blocks/Player.vue";

export default defineComponent({
    name: 'TimelineBlock',
    components: {
        Player
    },
    props: {
        isLoading: {
            type: Boolean,
            default: true,
        },
        playerWidth: {
            type: Number,
            default: 1850,
        },
        videoPlayerCurrentTime: {
            type: Number,
            default: 0,
        },
        videoPlayerDuration: {
            type: Number,
            default: 0,
        },
        tabIsSelected: {
            type: Function,
            required: true,
        },
        handleProtocolTimeClick: {
            type: Function,
            required: true,
        },
        changeVideoPlayerCurrentTimeAfterScrollCallback: {
            type: Function,
            required: true,
        },
        tabSelected: {
            type: Number,
            default: 0,
        }

    },
    emits: ['getHeightTimeline', 'editPersonCallback', 'changeCurrentTimeOfTimeline'],
    data() {
        return {}
    },

    async mounted() {
        const heightTimeline = this.$refs.splitpanesSkeleton?.clientHeight
        this.$emit('getHeightTimeline', heightTimeline)
    },
    methods: {
        editPersonCallback(person) {
            this.$emit('editPersonCallback', person);
        },
        changeCurrentTimeOfTimeline(time) {
            this.$emit('changeCurrentTimeOfTimeline', time)
        }

    },
})
