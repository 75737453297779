import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_instance_page = _resolveComponent("video-instance-page")!

  return (_ctx.videoId)
    ? (_openBlock(), _createBlock(_component_video_instance_page, {
        key: 0,
        id: _ctx.videoId,
        "access-ticket-id": _ctx.accessTicketId
      }, null, 8, ["id", "access-ticket-id"]))
    : _createCommentVNode("", true)
}