import {toVideo} from '@app/modules/video/video.store';
import {
    FilterOperatorType,
    IFilterItemDto,
    SpecificationParameters,
} from '@app/system/api/service-proxies/service-proxies';
import {IFilterRow} from '@app/types';
import {fields, FilterOperatorTypeEx, isNot, optionAnd} from '@app/utils/dictionary';

import {api, apiBaseUrl, httpService} from '@app/system/api/api-services';

export const includeEventsWithParticipants = new SpecificationParameters({
    // specId: 'FED4780F-ED4C-4829-8FE8-721938AE5C68',
    specId: '4bc43060-99d9-47c9-92ea-15b63da943e5',
    parameters: null,
});

export const includeThumbnailFileData = new SpecificationParameters({
    specId: '7AC1714B-828F-4A73-91C4-8FDFAF9072FA',
    parameters: null,
});

export const includeRawFileData = new SpecificationParameters({
    specId: '81814649-450B-481A-9E3A-A3A1FD2C1380',
    parameters: null,
});

export const includePreparedFileData = new SpecificationParameters({
    specId: 'E512DCB6-757D-4B54-92ED-157A827D5246',
    parameters: null,
});

export const includeProtocolData = new SpecificationParameters({
    specId: '4BC43060-99D9-47C9-92EA-15B63DA943E5',
    parameters: null,
});

export const includeObjectPermissions = new SpecificationParameters({
    specId: 'A7B111D6-1208-446C-B6B1-5A1A2824F5F7',
    parameters: null,
});

export const filterByState = (value: number, isNot: boolean = false) => new SpecificationParameters({
    specId: 'A1ADF60A-EA80-4D4A-8651-97BC9A446B55',
    parameters: {value, isNot},
});

export const filterILikeDescription = (query: string, isNot: boolean = false) => new SpecificationParameters({
    specId: '05CE7473-050D-434A-B244-C4B4E4BE1271',
    parameters: {value: `%${query}%`, isNot},
});

export const filterILikeConferenceDescription = (query: string, isNot: boolean = false) => new SpecificationParameters({
    specId: '5FC9E8DE-C830-4D83-95B2-4666F55FDA34',
    parameters: {value: `%${query}%`, isNot},
});

export const filterByParticipantId = (value: string, isNot: boolean = false) => new SpecificationParameters({
    specId: '6A95438D-F3AF-4BF7-B8CA-AA0F8297AF8A',
    parameters: {value, isNot},
});

export const filterByEmailModerator = (value: string, isNot: boolean = false) => new SpecificationParameters({
    specId: 'FB3BEEF3-A990-46EC-969A-84682A87BA60',
    parameters: {value, isNot},
});

export const filterByDate = (value: string, isNot: boolean = false) => new SpecificationParameters({
    specId: '55F14C77-E802-411D-B224-F12418E6279C',
    parameters: {value, isNot},
});

export const filterAfterDate = (value: string) => new SpecificationParameters({
    specId: 'A68954BE-E81D-4551-BF0D-65CAF98143AC',
    parameters: {value},
});

export const filterBeforeDate = (value: string) => new SpecificationParameters({
    specId: 'F61B3E08-E4B8-408B-8C5F-1AFE1FAF74C8',
    parameters: {value},
});

export const specId = '271CB390-D585-4595-B82A-B72710E75233';
export const filterByPeriod = (value: string) => new SpecificationParameters({
    specId,
    parameters: {value},
});

export const filterILikeContent = (query: string) => new SpecificationParameters({
    specId: '67B6E641-B95A-4587-815F-0F11C36A490D',
    parameters: {value: `%${query}%`},
});

export const filterByDurationInterval = (value: string, isNot: boolean = false) => new SpecificationParameters({
    specId: 'B280C0A8-9130-4F3D-806C-DB2E364B3116',
    parameters: {value, isNot},
});

export const filterByDuration = (value: string, isNot: boolean = false) => new SpecificationParameters({
    specId: '80087869-1C38-4AEC-9A6E-4D9953504B64',
    parameters: {value, isNot},
});

export const filterByRoomNumber = (value: string, isNot: boolean = false) => new SpecificationParameters({
    specId: 'CE3DB791-97F0-41D3-B56A-D64048F54555',
    parameters: {value, isNot},
});
export const filterByConfidentially = (value: string, isNot: boolean = false) => new SpecificationParameters({
    specId: '1F6F85B6-0F7B-4FEE-BEA4-0EDCBAE6409A',
    parameters: {value, isNot},
});
export const filterByDeletionProtection = (value: string, isNot: boolean = false) => new SpecificationParameters({
    specId: 'E553889D-2B42-407B-B607-C9634431FC9B',
    parameters: {value, isNot},
});

export async function getOptions() {
    /* const response = await api.settingsServiceProxy.getAll('1.0').toPromise();
     return response;*/
}

export interface FilterItem {
    type: string;
    operator: string;
    value: string | number | Date;
}

export interface FavoriteFilter {
    name: string;
    items: FilterItem[];
    createdBy: string;
    isSystem: boolean;
}

export async function getVideos(pageNumber: number, pageSize: number, rows: IFilterRow[] = []) {
    const specifications = [
        includeObjectPermissions,
        ...getVideosSpecifications(rows),
    ];

    const response = await api.videosServiceProxy.page(
        pageNumber,
        pageSize,
        true,
        '1.0',
        specifications,
    ).toPromise();
    response.data = response.data.map(item => toVideo(item) as any);
    return response;
}

export function getVideosSpecifications(rows: IFilterRow[]): SpecificationParameters[] {

    const filterItems = makeVideoFilterItems(rows);

    const videoSize = new SpecificationParameters({
        specId: 'E512DCB6-757D-4B54-92ED-157A827D5246',
        parameters: {value: true},
    });

    const includeThumbnailFileData = new SpecificationParameters({
        specId: '7AC1714B-828F-4A73-91C4-8FDFAF9072FA',
        parameters: {value: true},
    });

    const specifications: SpecificationParameters[] = [
        // filterByState(VideoState._20),
        videoSize,
        includeThumbnailFileData,
        ...filterItems.map(item => {

            if (item.operator === FilterOperatorTypeEx.Period as any) {
                return new SpecificationParameters({
                    specId: item.specId,
                    parameters: {
                        fromValue: (item.value as any)[0] || item.specValue,
                        toValue: (item.value as any)[1] || item.specValue,
                        isNot: (item.specValue as any)?.isNot || false,
                    },
                });
            }

            if (item.operator === FilterOperatorTypeEx.DeletionProtection as any || item.operator ===
                FilterOperatorTypeEx.Confidentially as any) {
                return new SpecificationParameters({
                    specId: item.specId,
                    parameters: {
                        value: true,
                    },
                });
            }
            if (item.operator === FilterOperatorTypeEx.NotDeletionProtection as any || item.operator ===
                FilterOperatorTypeEx.NotConfidential as any) {
                return new SpecificationParameters({
                    specId: item.specId,
                    parameters: {
                        value: false,
                    },
                });
            }

            return new SpecificationParameters({
                specId: item.specId,
                parameters: {
                    value: (item.specValue as any)?.value || item.specValue,
                    isNot: (item.specValue as any)?.isNot || false,
                },
            });
        }),
    ];

    return specifications;
}

export function makeVideoFilterItems(rows: IFilterRow[] | null): IFilterItemDto[] {
    if (!rows) {
        return [];
    }
    const items: IFilterItemDto[] = [];
    rows.forEach(row => {
        if (!row.fourthColumnVal) {
            // return [];
        }
        const {secondColumnVal: type, thirdColumnVal: operator, fourthColumnVal: value} = row;
        const partial = {
            propertyName: fields[type]?.propertyName || type,
            operator: operator,
            value: value,
        };
        let spec: SpecificationParameters | undefined = undefined;
        switch (type) {
            case 'name':
                if (value) {
                    spec = filterILikeDescription(value, isNot(operator));
                }
                break;
            case 'conference':
                if (value) {
                    spec = filterILikeConferenceDescription(value, isNot(operator));
                }
                break;
            case 'description':
                if (value) {
                    spec = filterILikeDescription(value, isNot(operator));
                }
                break;
            case 'date':
            case 'DocDate':
                if (!value) {
                    spec = undefined;
                } else if (operator === FilterOperatorTypeEx.GreaterThan) {
                    spec = filterAfterDate(value);
                } else if (operator === FilterOperatorTypeEx.LessThan) {
                    spec = filterBeforeDate(value);
                } else if (operator === FilterOperatorTypeEx.Period) {
                    spec = filterByPeriod(value);
                } else {
                    spec = filterByDate(value, isNot(operator));
                }
                break;
            case 'staff':
                if (value) {
                    spec = filterByParticipantId(value, isNot(operator));
                }
                break;
            case 'moderator':
                if (value) {
                    spec = filterByEmailModerator(value, isNot(operator));
                }
                break;
            case 'duration':
                if (!value) {
                    spec = undefined;
                } else if (operator === FilterOperatorTypeEx.Interval) {
                    spec = filterByDurationInterval(value, isNot(operator));
                } else {
                    spec = filterByDuration(value, isNot(operator));
                }
                break;
            case 'roomNumber':
                if (value) {
                    spec = filterByRoomNumber(value, isNot(operator));
                }
                break;
            case 'confidentially':
                spec = filterByConfidentially(value, isNot(operator));
                break;
            case 'deletionProtection':
                spec = filterByDeletionProtection(value, isNot(operator));
                break;
        }
        if (spec) {
            items.push({
                ...partial,
                operator: partial.operator as unknown as FilterOperatorType,
                specId: spec.specId,
                // specIsInverted: spec.parameters?.isNot || false,
                specValue: spec.parameters,
            });
        } else {
            console.error('Unknown filter type:', type);
            console.log(row);
        }
    });

    return items;
}

export function getFiltersBySpecifications(specifications: SpecificationParameters[]): IFilterRow[] {
    return specifications.map(specification => {
        const field = Object.values(fields).find(value => value.specIds?.includes(specification.specId));
        if (!field) {
            return null;
        }
        return {
            firstColumnVal: optionAnd.type,
            secondColumnVal: field.type,
            thirdColumnOptions: field.options,
            thirdColumnVal: specification.parameters.isNot ? field.options[1].type : field.options[0].type,
            fourthColumnVal: specification.parameters.value?.replace(/%/g, '') || '',
        };
    }).filter(row => !!row);
}

export function filterToSpecificationsString(filters: IFilterRow[]): string | null {
    const ignoreSpecificationIds = [
        'A1ADF60A-EA80-4D4A-8651-97BC9A446B55',
    ];
    const specifications = getVideosSpecifications(filters)
        .filter(specification => {
            return !ignoreSpecificationIds.includes(specification.specId);
        });

    return specifications?.length > 0 ? JSON.stringify(specifications) : null;
}

export async function updateVideo(video, partial) {
    const entity = {
        ...video,
        duration: video.durationText,
        ...partial,
    };
    try {
        entity.events = [];
        delete entity.texts;
        delete entity.participants;
        delete entity.preparedFileData;
        delete entity.demoParticipants;
        delete entity.participantMap;
        delete entity.participantToPrimaryParticipantMap;
    } catch (e) {
        console.log(e);
    }
    return api.videosServiceProxy.update('1.0', entity).toPromise();
}

export interface FileDataDto {
    id: string;
}

export async function uploadVideoFile(file: File): Promise<FileDataDto[]> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return httpService.post<FileDataDto[]>(`${apiBaseUrl}/api/recorder/upload/files`, formData, {
        headers: {
            // 'Content-Type': 'multipart/form-data'
        },
    }).toPromise();
}

export async function getSearchResults(pageNumber: number, pageSize: number, query, filters) {
    const videoSize = new SpecificationParameters({
        specId: 'E512DCB6-757D-4B54-92ED-157A827D5246',
        parameters: {value: true},
    });
    const specifications = [
        videoSize,
        includeProtocolData,
        includeObjectPermissions,
        filterILikeContent(query),
        // filterByState(VideoState._20),
    ];

    const response = await api.videosServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications).toPromise();
    response.data = response.data.map(item => toVideo(item) as any);
    return response;
}

export async function getVideosByIds(pageNumber: number, pageSize: number, video_ids) {
    // TODO: Спросить у Мавра почему теперь нет поля с ids 2 параметром
    const response = await api.videosServiceProxy.many('1.0', []).toPromise();
    Object.keys(response).forEach(key => {
        response[key] = toVideo(response[key]) as any;
    });
    return response;
}

export async function getSortedResults(
    pageNumber: number,
    pageSize: number,
    parameterName: String,
    sortingDirection: Boolean,
) {
    const videoSize = new SpecificationParameters({
        specId: 'E512DCB6-757D-4B54-92ED-157A827D5246',
        parameters: {value: true},
    });
    const sortByParameterName = new SpecificationParameters({
        specId: '',
        parameters: {isDescending: sortingDirection},
    });
    if (parameterName === 'title') sortByParameterName.specId = 'FBDA5320-3319-42DA-90EB-7A84C60D0CAF';
    if (parameterName === 'date') sortByParameterName.specId = '8FD2CC3D-EE91-4856-BE61-072FFF3924DB';
    if (parameterName === 'duration') sortByParameterName.specId = '9040820B-091B-49FE-BEA3-38DBBB5D2E63';
    if (parameterName === 'fileSize') sortByParameterName.specId = 'FE3BEF1A-9DF4-4284-ACAE-3991E6CF155E';
    if (parameterName === 'participants') sortByParameterName.specId = '13CF4737-31F9-45E4-A6B8-52DD66D4CF55';

    const specifications = [
        videoSize,
        sortByParameterName,
        includeProtocolData,
        includeObjectPermissions,
        // filterByState(VideoState._20),
    ];

    const response = await api.videosServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications).toPromise();
    response.data = response.data.map(item => toVideo(item) as any);
    return response;
}

export async function addVideoAccessForUser(videoId, userId) {
    // const payload = {
    //     'user_id': userId,
    //     'video_id': videoId,
    // };
    // const urlSegment = `videos/permissions/grant`;

    /*const response = await api.permissionsServiceProxy.grantPatch('1.0', new SetPermissionInput({
        user_id: userId,
        video_id: videoId,
    })).toPromise();
    return response;*/

    // const response = await axiosVinteoRecordApiWithCredentials.patch(urlSegment, payload);
    // return response.data;
}

export async function deleteVideoAccessForUser(videoId, userId) {
    // const payload = {
    //     'user_id': userId,
    //     'video_id': videoId,
    // };
    // const urlSegment = `videos/permissions/remove`;

    /*const response = await api.permissionsServiceProxy.removePatch('1.0', new SetPermissionInput({
        user_id: userId,
        video_id: videoId,
    })).toPromise();
    return response;*/

    // const response = await axiosVinteoRecordApiWithCredentials.patch(urlSegment, payload);
    // return response.data;
}

export async function changeVideoPublicity(videoId, publicity) {
    // const payload = {
    //     'access': publicity,
    // };
    // const urlSegment = `videos/${videoId}/access`;

    /*const response = await api.videosServiceProxy.access(videoId, '1.0', new ChangeAccessV01Input({
        'access': publicity,
    })).toPromise();
    return response;*/

    // const response = await axiosVinteoRecordApiWithCredentials.patch(urlSegment, payload);
    // return response.data;
}
