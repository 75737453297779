
import {defineComponent} from 'vue';

export default defineComponent({
    name: "SwitchBox",
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['update:modelValue'],

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },

    methods: {}
})

