import axios from 'axios';

import {mocks} from '@app/system/api/mocks';

/* Vinteo Record API */

export const axiosVinteoRecordApi = axios.create({
    // baseURL: `${config.baseUrl}\\${config.apiVersionStr}`,
    baseURL: 'https://rec.ntrnx.com/api/master',
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

/* Vinteo Record API with credentials */
export const axiosVinteoRecordApiWithCredentials = axios.create({
    // baseURL: `${config.baseUrl}\\${config.apiVersionStr}`,
    baseURL: 'https://rec.ntrnx.com/api/master',
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});
// Интерцептор: прикрепляет заголовок авторизации
axiosVinteoRecordApiWithCredentials.interceptors.request.use(
    (config) => {
        // const jwt = store.getters['auth/jwt']();
        const jwt = mocks.apiMasterJwt;

        if (jwt) {
            config.headers['Authorization'] = `Bearer ${jwt}`;
        }
        // Иначе отправить без заголовка Authorization

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
// Интерцептор: проверка на 401 HTTP ответ
axiosVinteoRecordApiWithCredentials.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    const status = error.response ? error.response.status : null;

    if (status !== 401) {
        return Promise.reject(error);
    }

    // Delete JWT from localStorage
    // localStorage.removeItem('jwt');
    // Redirect to SignIn page
    // router.push({name: 'sign-in'});

    return Promise.reject(error);
});

