import {i18n} from '@app/i18n';
import {api} from '@app/system/api/api-services';
import {IAccessGroupDto, SpecificationParameters} from '@app/system/api/service-proxies/service-proxies';

function initialState() {
    return {
        isLoading: false,
        isServerError: false,
        serverErrorMessage: null,
        roles: [],
        allRoles: [],
        rolesCount: 0,
        perPage: 10,
        currentPage: 1,

        searchStr: '',
    };
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },

    isServerError(state) {
        return state.isServerError;
    },

    serverErrorMessage(state) {
        return state.serverErrorMessage;
    },

    roles(state) {
        return state.roles;
    },

    allRoles(state) {
        return state.allRoles;
    },

    rolesCount(state) {
        return state.rolesCount;
    },

    perPage(state) {
        return state.perPage;
    },

    currentPage(state) {
        return state.currentPage;
    },
};

const actions = {
    getList({commit, state}) {

        commit('setLoading');

        const pageSize = state.perPage;
        const pageNumber = state.currentPage;

        const searchStr = state.searchStr;

        const filterForSearch = new SpecificationParameters({
            specId: '90CF6AD2-AA38-48BA-8FFC-B51A8955AF8D',
            parameters: {value: `%${searchStr}%`},
        });
        const IncludeOrganizationUnit = new SpecificationParameters({
            specId: 'BE9C4D6E-3C91-4016-8772-01E3DD8DB976',
            parameters: {value: `%${searchStr}%`},
        });
        const IncludeRole = new SpecificationParameters({
            specId: '761C2AE3-022A-4388-AB89-49EAC2EF9069',
            parameters: {value: `%${searchStr}%`},
        });
        const IncludeFilterSet = new SpecificationParameters({
            specId: 'DE918BF6-9303-4784-9C0F-96A2129B3420',
            parameters: {value: `%${searchStr}%`},
        });

        const specifications = [];

        if (searchStr.length) {
            specifications.push(filterForSearch);
            specifications.push(IncludeOrganizationUnit);
            specifications.push(IncludeRole);
            specifications.push(IncludeFilterSet);
        }

        return api.accessGroupServiceProxy.pagePost(pageNumber, pageSize, true, '1.0', specifications)
            .toPromise()
            .then(resp => {
                commit('setLoaded');
                commit('setRoles', resp.data);
                commit('setRolesCount', resp.totalCount);
                return resp.data;
            })
            .catch(err => {
                console.log(err);
                commit('setLoaded');
                commit('setServerError', err);
                return [];
            });
    },

    async getAllList({commit}, payload) {
        const response = await api.accessGroupServiceProxy.pagePost(1, 1000, true, '1.0', [])
            .toPromise()
            .then(resp => {
                commit('setLoaded');
                commit('setAllRoles', resp.data);
                return resp.data;
            })
            .catch(err => {
                console.log(err);
                commit('setLoaded');
                commit('setServerError', err);
                return [];
            });
        return response;
    },

    getInstance({commit}, id) {
        commit('setLoading');

        return new Promise((resolve, reject) => {
            api.accessGroupServiceProxy.getGet(id, '1.0').toPromise()
                .then(resp => {
                        commit('setLoaded');
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    commit('setLoaded');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },

    async save({commit}, payload: IAccessGroupDto) {
        commit('setLoading');

        return api.accessGroupServiceProxy.savePost('1.0', payload as any)
            .toPromise()
            .then(resp => {
                commit('setLoaded');
            })
            .catch(err => {
                commit('setLoaded');
                commit('setServerError', err);
            });
    },

    update({commit}, payload: IAccessGroupDto) {
        commit('setLoading');

        return api.accessGroupServiceProxy.updatePut('1.0', payload as any)
            .toPromise()
            .then(resp => {
                commit('setLoaded');
            })
            .catch(err => {
                commit('setLoaded');
                commit('setServerError', err);
            });
    },

    delete({commit}, id) {
        commit('setLoading');

        return new Promise((resolve, reject) => {
            api.accessGroupServiceProxy.deleteDelete(id, '1.0').toPromise()
                .then(resp => {
                        commit('setLoaded');
                        resolve(resp);
                    },
                )
                .catch((err) => {
                    commit('setLoaded');
                    commit('setServerError', err);
                    reject(err);
                });
        });
    },
};

/* eslint-disable no-param-reassign */
const mutations = {
    setLoading(state) {
        state.isLoading = true;
        state.isServerError = false;
        state.serverErrorMessage = null;
    },

    setLoaded(state) {
        state.isLoading = false;
    },

    setServerError(state, err) {
        state.isServerError = true;
        if (err.response && err.response.data && err.response.data.error) {
            state.serverErrorMessage = err.response.data.error;
        } else {
            state.serverErrorMessage = i18n.global.t('unknown_error');
        }
    },

    setRoles(state, roles) {
        state.roles = roles;
    },

    setAllRoles(state, roles) {
        state.allRoles = roles;
    },

    setRolesCount(state, count) {
        state.rolesCount = count;
    },

    setPaginationInfo(state, paginationInfo) {
        state.perPage = paginationInfo.perPage;
        state.currentPage = paginationInfo.currentPage;
    },

    setSearchStr(state, searchStr) {
        state.searchStr = searchStr;
    },
    resetSearchStr(state) {
        state.searchStr = '';
    },
    setCurrentPage(state, payload) {
        state.currentPage = payload;
    },
};

/* eslint-enable no-param-reassign */
export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
