import localAppSettings from '@app/config/appsettings.json';

import baseConfig from './base.config';

export const config = {
    ...baseConfig,
};

export let appSettings = localAppSettings;

export function setAppSettings(settings) {
    appSettings = settings;
}
