import {api} from '@app/system/api/api-services';
import {BrandingSettingsDto, NotificationsSettingsDto} from '@app/system/api/service-proxies/service-proxies';

function initialState() {
    return {
        settingsUserNotification: null,
    };
}

const getters = {
    settingsUserNotification(state) {
        return state.settingsUserNotification
    },
};

const actions = {
    async getSettingsUserNotification({commit}): Promise<NotificationsSettingsDto> {
        try {
            const resp = await api.userServiceProxy.notifications('1.0').toPromise();
            commit('settingsUserNotification', resp);
            return resp;
        } catch (error) {
            console.error(error);
        }
    },

    async saveSettingsUserNotification({commit}, settings) {
        try {
            await api.notificationsServiceProxy.savePost('1.0', settings).toPromise();

        } catch (error) {
            console.error(error);
        }
    },
};

const mutations = {
    setSettingsUserNotification(state, settings) {
        state.settingsUserNotification = settings
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
