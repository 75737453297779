import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form = _resolveComponent("b-form")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    id: _ctx.id,
    ref: "modal",
    "modal-show": _ctx.modalShow,
    title: _ctx.title,
    size: "lg",
    "ok-title": _ctx.buttonText,
    "ok-variant": "danger",
    "cancel-title": _ctx.$t('buttons.cancel'),
    "is-delete": true,
    onShow: _ctx.resetModal,
    onOk: _ctx.submitForm,
    onHidden: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      (_ctx.questionText && !_ctx.selectedVideos)
        ? (_openBlock(), _createBlock(_component_b_form, {
            key: 0,
            class: "mb-4",
            onSubmit: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.subtitle) + " ", 1),
              _createElementVNode("strong", null, _toDisplayString(_ctx.questionText), 1),
              _createTextVNode("? ")
            ]),
            _: 1
          }, 8, ["onSubmit"]))
        : (_ctx.selectedVideos)
          ? (_openBlock(), _createBlock(_component_b_form, {
              key: 1,
              class: "mb-4",
              onSubmit: _ctx.submitForm
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.subtitle) + " ", 1),
                _createElementVNode("strong", null, _toDisplayString(_ctx.selectedVideos), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('navs.video')) + "? ", 1)
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : (_openBlock(), _createBlock(_component_b_form, {
              key: 2,
              class: "mb-4",
              onSubmit: _ctx.submitForm
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.subtitle) + "? ", 1)
              ]),
              _: 1
            }, 8, ["onSubmit"]))
    ]),
    _: 1
  }, 8, ["id", "modal-show", "title", "ok-title", "cancel-title", "onShow", "onOk", "onHidden"]))
}