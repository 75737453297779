
import {defineComponent} from 'vue'
import {copyText} from 'vue3-clipboard';
import {mapActions, mapState} from "vuex";

export default defineComponent({
    name: 'CopyVideoLinkBtn',
    props: {
        publicAccess: {
            type: Number,
            required: true
        },
        videoId: {
            type: String,
            required: true
        },
        accessComponent: {
            type: Boolean,
            required: false
        },
        accessTicket: {
            type: Object,
            required: true
        }
    },
    emits: ['showMessageSuccessCopyVideoUrl'],

    computed: {
        ...mapState('video', [
            'shareId',
            'video'
        ]),
    },

    methods: {
        ...mapActions('video', [
            'getShareId'
        ]),
        clipboardCopyText() {
            const accessTicketId = this.accessTicket.id;
            // const videoId = this.accessTicket.targetUrl.split('/')[2];

            const path = this.$router.resolve({name: 'share', params: {accessTicketId}}).href;
            const fullUrl = window.location.origin + path

            copyText(fullUrl, undefined, (error, event) => {
                console.log(error);
                if (event.action) {
                    this.$emit('showMessageSuccessCopyVideoUrl')
                }
            });
        }
    },
});
