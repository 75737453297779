

import {defineComponent} from 'vue';

export default defineComponent({
    name: 'NotFoundPage',
    components: {},
    methods: {
        goToMain() {
            this.$router.push('/')
        }
    }
});
