
import {defineComponent} from 'vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';

import UiErrorSnackbar from "@app/components/ui-kit/molecules/ErrorSnackbar.vue";
import {setShowToastErrorHandler, setShowToastSuccessHandler} from '@app/system/api/xhr-factory';
import UserActivityExpiredModal from "@app/views/blocks/UserActivityExpiredModal.vue";
import UiSuccessSnackbar from '@app/components/ui-kit/molecules/SuccessSnackbar.vue';

export default defineComponent({
    name: 'App',
    components: {
        UiSuccessSnackbar,
        UiErrorSnackbar,
        UserActivityExpiredModal,
    },
    data: () => ({
        loadDataAttempts: 0,
    }),

    computed: {
        ...mapGetters('brandingSettings', {
            brandingSettings: 'brandingSettings',
            favicon: 'favicon'
        })
    },

    watch: {
        brandingSettings() {
            this.setCSSVariables(this.brandingSettings);
            this.getBrandingFile({fileType: 'logo',fileId: this.brandingSettings?.logoFileDataId})
            this.getBrandingFile({fileType: 'favicon',fileId: this.brandingSettings?.faviconFileDataId})
        },
        favicon(newFavicon) {
            this.updateFavicon(newFavicon.url);
        }
    },

    mounted() {
        setShowToastErrorHandler((errorText: string) => {
            this.$refs.errorSnackbar.show({message: errorText});
        });

        setShowToastSuccessHandler((message: string) => {
            this.$refs.successSnackbar.show({message});
        });

        this.loadData();
        this.updateFavicon(this.favicon?.url);
    },

    methods: {
        ...mapActions('video', {
            setParticipantFullName: 'setParticipantFullName'
        }),
        ...mapMutations('video', {
            setViewScreenVideos: 'setViewScreenVideos',
        }),
        ...mapActions('brandingSettings', {
            getBrandingSettings: 'getBrandingSettings',
            getBrandingFile: 'getBrandingFile'
        }),

        loadData() {
            this.loadDataAttempts++;
            

            setTimeout(() => {
                const $oidc = window['$oidc'];
                const accessToken = $oidc.accessToken || null;
                if (accessToken) {
                    this.setParticipantFullName();
                    // прогрузка настроек брендинга ломала процедуру логина
                    // перенёс запрос брендинга туда, где токен уже получен
                    this.getBrandingSettings()
                } else if (this.loadDataAttempts < 5) {
                    this.loadData();
                }
            }, 500);
        },

        setCSSVariables(settings) {
            const root = document.documentElement;
            root.style.setProperty('--font-color', settings.baseTextColor);
            root.style.setProperty('--background-color-body', settings.backgroundColor);
            root.style.setProperty('--background-color-plate', settings.tableItemColor);
            root.style.setProperty('--accent-color', settings.accentColor);
            root.style.setProperty('--color-active-menu-item', settings.colorActiveMenuItem);

        },
        updateFavicon(url) {
            let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;;
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = url;
        }
    },
});
