
import {defineComponent} from 'vue';

import {getWidthScreen} from "@app/utils/helpers";
import {mapGetters} from "vuex";

export default defineComponent({
    name: "ButtonCreate",
    props: {
        isGrantedPolicy: {
            type: Boolean,
            required: true,
        },
        buttonText: {
            type: String,
            default: ''
        },
        recognized: {
            type: Boolean,
            default: null
        },
    },
    emits: ['callBack'],

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicyForSome: 'isGrantedPolicyForSome',
        }),
        isMobile() {
            return getWidthScreen()
        },
        isGrantedForUpdate() {
            return this.isGrantedPolicyForSome([
                'Recorder.Video.Update'
            ]);
        }
    },
    methods: {
        handleClick() {
            this.$emit('callBack')
        }
    }
})

